import React from 'react';
import { BsSquare, BsFillCheckSquareFill, BsCheckCircleFill, BsCircle } from 'react-icons/bs';

export default function FiltroItem({ item, checked, onChange, selezioneSingola }) {

    const styleCheckBox = {
        'fontSize': '1.4rem',
        'position': 'relative',
        'top': '-1px'
    }

    let IconaChecked;
    let IconaUnchecked;
    if (selezioneSingola) {
        IconaChecked = <BsCheckCircleFill style={styleCheckBox} />;
        IconaUnchecked = <BsCircle style={styleCheckBox} />;
    } else {
        IconaChecked = <BsFillCheckSquareFill style={styleCheckBox} />;
        IconaUnchecked = <BsSquare style={styleCheckBox} />;
    }

    const stile = checked ?
        { 'fontWeight': '500', 'color': 'rgb(0, 109, 151)', 'backgroundColor': 'aliceblue' } :
        { 'fontWeight': 'normal', 'color' : 'black', 'backgroundColor': 'white' };

    return (
        <div className="item-filtro mb-1" onClick={() => onChange(item)} style={stile} title={item.dettaglio}>
            {checked ? IconaChecked : IconaUnchecked}
            <span className="ms-1">{item.descrizione.toUpperCase()}</span>
        </div>
    )
}