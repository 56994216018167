import React from 'react';
import Filtro from './Filtro';
import useFetch from '../../hooks/useFetch';
import { MultiSelect } from 'primereact/multiselect';

export default function FiltroCircuiti({
  selectAll,
  onChange,
  maxHeight,
  disabled = false,
  mode = 'table',
  titolo = null,
  itemsSelected = []
}) {
  const { data, error } = useFetch('liste/circuiti');

  return mode === 'table' ? (
    <Filtro
      titolo={titolo ? titolo : 'circuiti'}
      dati={data}
      errore={error?.message}
      onChange={onChange}
      selectAll={selectAll}
      maxHeight={maxHeight}
    />
  ) : (
    <MultiSelect
      value={itemsSelected}
      options={data}
      onChange={(e) => onChange(e.value)}
      optionLabel="descrizione"
      optionValue="valore"
      disabled={disabled}
      virtualScrollerOptions={{ itemSize: 40, scrollHeight: '29rem' }}
      filter
      placeholder="Seleziona una o più circuiti"
      display="chip"
      className="w-100"
    />
  );
}
