import React, { useCallback, useState, Fragment } from 'react';
import { BsSearch } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import {
  colori,
  NomiGiorniUS,
  printData,
  printInteroConSeparatoriMigliaia
} from '../../common/utils';
import EsportaDati from '../EsportaDati';
import Paginazione from './Paginazione';

const MAX_VOCI = 25;
const FONT_SIZE_HEADERS = 1.05
const FONT_SIZE_HEADERS_TITLE = 1.4
const FONT_SIZE_BODY = 1.2

export default function BodyTabella({ dati, films, tipoCumulato, campi }) {
  const [pagina, setPagina] = useState(0);
  const [rigaSelezionata, setRigaSelezionata] = useState();

  const calcFontSize = (length, value, max) => {
    const fontSize = value-(length/100 * length);

    return fontSize < max ? value-(length/100 * length): max
  }

  const cambiaRiga = useCallback((riga) => {
    setRigaSelezionata(riga);
    setPagina(0);
  }, []);

  for (let f = 0; f < films.length; f++) {
    films[f].incassi_tot = 0;
    films[f].presenze_tot = 0;
    let cinema_unici = [];
    for (let p = 0; p < dati.length; p++) {
      if (dati[p].films[f]) {
        films[f].incassi_tot += dati[p].films[f].incassi;
        films[f].presenze_tot += dati[p].films[f].presenze;
        cinema_unici.push(dati[p].films[f].dettagli.map((x) => x.progress_cinema));
      }
    }
    films[f].cinema_tot = Array.from(new Set(cinema_unici.flat(1))).length;
  }

  const intestazione = (
    <div
      className="row text-center"
      style={{ textTransform: 'uppercase', color: 'white', fontWeight: '500' }}>
      {films.map((t, idx) => {
        return (
          <div key={t.titolo} className="col py-1" style={{ backgroundColor: colori[idx] }}>
            <div className="row">
              <div className="col py-1" style={{ fontSize: `${calcFontSize(films.length, FONT_SIZE_HEADERS_TITLE, 1.2)}rem`}}>
                <Link
                  to={'/films/' + t.progress_film}
                  className="link-bianco position-relative me-1"
                  style={{ top: '-2px' }}>
                  <BsSearch className="me-1" title="Clicca per aprire il link" />
                </Link>
                {t.titolo.substring(0, 40) + (t.titolo.length > 40 ? '...' : '')}
              </div>
            </div>
            <div className="row">
              <div className="col" style={{ maxWidth: '4rem' }}></div>
              <div className="col" style={{ fontSize: `${calcFontSize(films.length, FONT_SIZE_HEADERS, 1)}rem`}}>periodo</div>
              {campi.indexOf('incassi') !== -1 && <div className="col" style={{ fontSize: `${calcFontSize(films.length, FONT_SIZE_HEADERS, 1)}rem`}}>incassi</div>}
              {campi.indexOf('presenze') !== -1 && <div className="col" style={{ fontSize: `${calcFontSize(films.length, FONT_SIZE_HEADERS, 1)}rem`}}>presenze</div>}
              {campi.indexOf('cinema') !== -1 && <div className="col" style={{ fontSize: `${calcFontSize(films.length, FONT_SIZE_HEADERS, 1)}rem`}}>cinema</div>}
            </div>
          </div>
        );
      })}
    </div>
  );

  const getDomCinema = (riga) => {
    const dati_films = dati[riga].films;

    // trova la lista dei cinema dove sono stati proiettati tutti i film senza duplicati
    let all_cinema = [];

    for (let f = 0; f < dati_films.length; f++) {
      if (dati_films[f] !== null) {
        for (let c = 0; c < dati_films[f].dettagli.length; c++) {
          const dato = dati_films[f].dettagli[c];
          let pos_cinema_trovato = all_cinema.findIndex(
            (x) => x.progress_cinema === dato.progress_cinema
          );
          if (pos_cinema_trovato === -1) {
            all_cinema.push({
              progress_cinema: dato.progress_cinema,
              nome_cinema: dato.nome_cinema,
              citta: dato.citta,
              numero_sale: dato.numero_sale,
              incassi: 0,
              presenze: 0,
              films: []
            });
            pos_cinema_trovato = all_cinema.length - 1;
          }
          all_cinema[pos_cinema_trovato].incassi += dato.incassi;
          all_cinema[pos_cinema_trovato].presenze += dato.presenze;
        }
      }
    }

    for (let c = 0; c < all_cinema.length; c++) {
      const cinema_corrente = all_cinema[c];
      for (let f = 0; f < dati_films.length; f++) {
        let film = {
          incassi: 0,
          presenze: 0
        };
        if (dati_films[f] !== null) {
          const cinema = dati_films[f].dettagli
            .filter((x) => x.progress_cinema === cinema_corrente.progress_cinema)
            .reduce(
              (a, c) => {
                a.incassi += c.incassi;
                a.presenze += c.presenze;
                return a;
              },
              { incassi: 0, presenze: 0 }
            );
          if (cinema) {
            film = {
              incassi: cinema.incassi,
              presenze: cinema.presenze
            };
          }
        }
        cinema_corrente.films.push(film);
      }
    }
    all_cinema = all_cinema?.sort((a, b) => b.incassi - a.incassi);

    return (
      <div className="col-12 p-3 p-lg-5" style={{ backgroundColor: 'white' }}>
        <h3 className="mb-3">
          Cinema ordinati per incasso (periodo{' '}
          {(dati[riga].offset >= 0 ? '+' : '') + dati[riga].offset})
        </h3>
        <Paginazione
          pagina={pagina}
          numVoci={all_cinema.length}
          maxVoci={MAX_VOCI}
          onChange={setPagina}
        />
        <div className="row" style={{ fontWeight: '500', lineHeight: '1.2' }}>
          <div className="col-4"></div>
          {films.map((f, idx) => {
            return (
              <div
                className="col py-1"
                key={idx}
                style={{ backgroundColor: colori[idx], color: 'white' }}>
                {f.titolo.substring(0, 30) + (f.titolo.length > 30 ? '...' : '')}
              </div>
            );
          })}
        </div>
        {all_cinema.slice(pagina * MAX_VOCI, (pagina + 1) * MAX_VOCI).map((x, pos) => {
          return (
            <div
              className="row"
              key={x.progress_cinema}
              style={{ borderBottom: '1px dotted gray' }}>
              <div className="col-4 text-start">
                {pagina * MAX_VOCI + pos + 1}) {x.nome_cinema} ({x.numero_sale} sal
                {x.numero_sale !== 1 ? 'e' : 'a'}) - {x.citta}
              </div>
              {x.films.map((f, idx) => {
                return (
                  <div className="col" key={idx}>
                    {f.incassi > 0 ? printInteroConSeparatoriMigliaia(f.incassi) + '€' : '-'}
                  </div>
                );
              })}
            </div>
          );
        })}
        {/* <div className="row">
          <EsportaDati data={dati} nomeFile="Analisi" disabled={true} />
        </div> */}
      </div>
    );
  };

  const body = (
    <Fragment>
      {dati.map((x, row) => {
        return (
          <div
            key={x.offset}
            className="row align-items-center text-center"
            style={{
              backgroundColor: row % 2 === 0 ? 'whitesmoke' : 'white',
              lineHeight: '1.7',
              borderBottom: '1px solid gainsboro'
            }}>
            <div
              className="col-12"
              onClick={() => cambiaRiga((val) => (val !== row ? row : -1))}
              style={{ cursor: 'pointer' }}>
              <div className="row align-items-center">
                {x.films.map((f, idx) => {
                  let colonna0 = '-';
                  let colonna1 = '-';
                  let colonna2 = '-';
                  let colonna2cum = '-';
                  let colonna3 = '-';
                  let colonna3cum = '-';
                  let colonna4 = '-';
                  let colonna4cum = '-';
                  if (f !== null) {
                    if (x.offset >= 0) colonna0 = (x.offset >= 0 ? '+' : '') + x.offset;
                    else colonna0 = <abbr title="Anteprima">A</abbr>;
                    colonna1 = printData(new Date(f.inizio));
                    if (f.inizio !== f.fine)
                      colonna1 = colonna1 + '-' + printData(new Date(f.fine));
                    else {
                      const data = new Date(f.inizio);
                      colonna1 =
                        NomiGiorniUS[data.getDay()].substring(0, 3).toUpperCase() + ' ' + colonna1;
                    }
                    const dati_film = dati.filter(
                      (y) => y.films[idx] !== null && y.offset <= x.offset
                    );

                    colonna2 =
                      f.incassi > 0 ? printInteroConSeparatoriMigliaia(f.incassi) + '€' : '-';
                    colonna3 = f.presenze > 0 ? f.presenze : '-';
                    colonna4 = f.cinema > 0 ? f.cinema : '-';
                    if (tipoCumulato > 0) {
                      const incasso_cumulato = dati_film
                        .map((x) => x.films[idx].incassi)
                        .reduce((partialSum, a) => partialSum + a, 0);
                      const presenze_cumulate = dati_film
                        .map((x) => x.films[idx].presenze)
                        .reduce((partialSum, a) => partialSum + a, 0);
                      let id_cinema = [];
                      for (let p = 0; p < dati_film.length; p++) {
                        const film = dati_film[p].films[idx];
                        id_cinema.push(...film.dettagli.map((x) => x.progress_cinema));
                      }
                      const num_cinema_unici = Array.from(new Set(id_cinema)).length;

                      if (tipoCumulato === 1) {
                        colonna2cum =
                          incasso_cumulato > 0
                            ? printInteroConSeparatoriMigliaia(incasso_cumulato) + '€'
                            : '-';
                        colonna3cum =
                          presenze_cumulate > 0
                            ? printInteroConSeparatoriMigliaia(presenze_cumulate)
                            : '-';
                        colonna4cum =
                          num_cinema_unici > 0
                            ? printInteroConSeparatoriMigliaia(num_cinema_unici)
                            : '-';
                      } else if (tipoCumulato === 2) {
                        colonna2cum =
                          incasso_cumulato > 0
                            ? Math.round(
                                (incasso_cumulato / films[idx].incassi_tot) * 100 * 10,
                                1
                              ) /
                                10 +
                              '%'
                            : '-';
                        colonna3cum =
                          presenze_cumulate > 0
                            ? Math.round(
                                (presenze_cumulate / films[idx].presenze_tot) * 100 * 10,
                                1
                              ) /
                                10 +
                              '%'
                            : '-';
                        colonna4cum =
                          num_cinema_unici > 0
                            ? Math.round((num_cinema_unici / films[idx].cinema_tot) * 100 * 10, 1) /
                                10 +
                              '%'
                            : '-';
                      }
                    }
                  }

                  const is_incasso_max = films[idx].max_incassi === f?.incassi;
                  const is_incasso_min = films[idx].min_incassi === f?.incassi;
                  const is_presenze_max = films[idx].max_presenze === f?.presenze;
                  const is_presenze_min = films[idx].min_presenze === f?.presenze;
                  const is_cinema_max = films[idx].max_cinema === f?.cinema;
                  const is_cinema_min = films[idx].min_cinema === f?.cinema;
                  return (
                    <Fragment key={idx}>
                      <div className="col px-1" style={{ maxWidth: '4rem', fontSize: `${calcFontSize(films.length, FONT_SIZE_BODY, 0.9)}rem` }}>
                        {colonna0}
                      </div>
                      <div
                        className="col px-1"
                        style={{
                          lineHeight: '1.1',
                          // fontSize: colonna1.indexOf('-') === -1 ? '1rem' : '0.9rem'
                          fontSize: `${calcFontSize(films.length, FONT_SIZE_BODY, 0.9)}rem`
                        }}>
                        {colonna1.split('-').map((line, i) => (
                          <span key={i}>
                            {line}
                            <br />
                          </span>
                        ))}
                      </div>
                      {campi.indexOf('incassi') !== -1 && (
                        <div className="col px-1 py-1" style={{ lineHeight: '1.7' }}>
                          <span
                            className={
                              is_incasso_max
                                ? 'badge bg-success shadow px-2'
                                : is_incasso_min
                                ? 'badge bg-warning shadow px-2'
                                : ''
                            }
                            style={{ 
                              fontWeight: 'normal', 
                              // fontSize: '1rem'
                              fontSize: `${calcFontSize(films.length, FONT_SIZE_BODY, 0.9)}rem` ,
                            }}>
                            {colonna2}
                          </span>
                          {tipoCumulato > 0 && (
                            <span
                              className="d-block mt-1"
                              style={{
                                // fontSize: '0.8rem',
                                fontSize: `${calcFontSize(films.length, FONT_SIZE_BODY, 0.9)}rem`,
                                lineHeight: '1.0',
                                color: 'gray'
                              }}>
                              {colonna2cum}
                            </span>
                          )}
                        </div>
                      )}
                      {campi.indexOf('presenze') !== -1 && (
                        <div className="col px-1 py-1" style={{ lineHeight: '1.7' }}>
                          <span
                            className={
                              is_presenze_max
                                ? 'badge bg-success shadow px-2'
                                : is_presenze_min
                                ? 'badge bg-warning shadow px-2'
                                : ''
                            }
                            style={{ 
                              fontWeight: 'normal', 
                              // fontSize: '1rem' 
                              fontSize: `${calcFontSize(films.length, FONT_SIZE_BODY, 0.9)}rem`,
                            }}>
                            {colonna3 !== '-' ? printInteroConSeparatoriMigliaia(colonna3) : '-'}
                          </span>
                          {tipoCumulato > 0 && (
                            <span
                              className="d-block mt-1"
                              style={{
                                // fontSize: '0.8rem',
                                fontSize: `${calcFontSize(films.length, FONT_SIZE_BODY, 0.9)}rem`,
                                lineHeight: '1.0',
                                color: 'gray'
                              }}>
                              {colonna3cum}
                            </span>
                          )}
                        </div>
                      )}
                      {campi.indexOf('cinema') !== -1 && (
                        <div className="col px-1 py-1" style={{ lineHeight: '1.7' }}>
                          <span
                            className={
                              is_cinema_max
                                ? 'badge bg-success shadow px-2'
                                : is_cinema_min
                                ? 'badge bg-warning shadow px-2'
                                : ''
                            }
                            style={{ 
                              fontWeight: 'normal', 
                              // fontSize: '1rem' 
                              fontSize: `${calcFontSize(films.length, FONT_SIZE_BODY, 0.9)}rem`,
                            }}>
                            {colonna4}
                          </span>
                          {tipoCumulato > 0 && (
                            <span
                              className="d-block mt-1"
                              style={{
                                // fontSize: '0.8rem',
                                fontSize: `${calcFontSize(films.length, FONT_SIZE_BODY, 0.9)}rem`,
                                lineHeight: '1.0',
                                color: 'gray'
                              }}>
                              {colonna4cum}
                            </span>
                          )}
                        </div>
                      )}
                    </Fragment>
                  );
                })}
              </div>
            </div>
            <div className="col-12">
              <div className="row">{row === rigaSelezionata && getDomCinema(row)}</div>
            </div>
          </div>
        );
      })}
      <div
        className="row align-items-center text-center"
        style={{
          fontSize: '1.1rem',
          fontWeight: '500',
          lineHeight: '1.2',
          color: 'white',
          borderBottom: '1px solid gainsboro'
        }}>
        <div className="col-12">
          <div className="row align-items-center">
            {films.map((f, idx) => {
              return (
                <div key={idx} className="col py-1" style={{ backgroundColor: colori[idx] }}>
                  <span style={{ fontWeight: 'normal', fontSize: '1rem' }}>Incassi totali:</span>{' '}
                  {f.incassi_tot > 0 ? printInteroConSeparatoriMigliaia(f.incassi_tot) + '€' : '-'}
                  <br />
                  <span style={{ fontWeight: 'normal', fontSize: '1rem' }}>
                    Presenze totali:
                  </span>{' '}
                  {f.presenze_tot > 0 ? printInteroConSeparatoriMigliaia(f.presenze_tot) : '-'}
                  <br />
                  <span style={{ fontWeight: 'normal', fontSize: '1rem' }}>
                    Cinema totali:
                  </span>{' '}
                  {f.cinema_tot > 0 ? f.cinema_tot : '-'}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Fragment>
  );

  return (
    <Fragment>
      {intestazione}
      {body}
    </Fragment>
  );
}
