import React, { useMemo } from "react";
import Filtro from "../filtri/Filtro";


export default function FiltroVersioni({ versioni, setVersioniSelezionate }) {
    
    const versioni_trovate = useMemo(() => {
        if (!versioni)
            return [];

        return versioni.map(x => {
            return {
                valore: x,
                descrizione: x
            }
        })
    }, [versioni])

    
    

    return (
        <Filtro
            titolo='Versioni film'
            dati={versioni_trovate}
            onChange={setVersioniSelezionate}
            showNumeroVoci={false}
            selezioneRichiesta={true}
            selectAll={true}
            showRicerca={false}
        />
    )

}