import React from 'react';
import { Accordion } from 'react-bootstrap';
import ItemProvincia from './ItemProvincia';
import EsportaDati from '../EsportaDati';

export default function TabellaRegioni({ dati, progress_film, versioni }) {
  if (versioni.length === 0) {
    return null;
  }

  const agenzie = Array.from(new Set(dati.map((d) => d.agenzia)));

  function getDomCitta(agenzia) {
    const dati_citta = dati.filter((d) => d.agenzia === agenzia);
    const province_agenzia = Array.from(new Set(dati_citta.map((x) => x.citta)));

    let dati_province = province_agenzia.map((provincia) => {
      const dati_provincia = dati_citta.filter(
        (d) => d.citta === provincia && versioni.indexOf(d.versione) !== -1
      );

      if (dati_provincia.length === 0) {
        return null;
      }
      const incassi = dati_provincia.reduce((partialSum, a) => partialSum + a.incassi, 0);
      const presenze = dati_provincia.reduce((partialSum, a) => partialSum + a.presenze, 0);

      return {
        citta: dati_provincia[0].citta,
        inizio: Math.min(...dati_provincia.map((x) => new Date(x.inizio))),
        fine: Math.max(...dati_provincia.map((x) => new Date(x.fine))),
        presenze: presenze,
        incassi: incassi,
        sigla_provincia: dati_provincia[0].sigla_provincia
      };
    });

    dati_province = dati_province && dati_province.filter((x) => x !== null);
    if (dati_province?.length > 0) {
      if (dati_province.length > 1) dati_province.sort((a, b) => b['incassi'] - a['incassi']);
      return dati_province.map((x) => {
        return (
          <ItemProvincia
            key={x.citta}
            dati={x}
            dati_provincia={dati.filter(
              (d) => d.citta === x.citta && versioni.indexOf(d.versione) !== -1
            )}
            progress_film={progress_film}
          />
        );
      });
    }

    return [];
  }

  return (
    <div className="mt-5 mb-3">
      <Accordion id="accordion-regioni" defaultActiveKey="0">
        {agenzie.map((r, idx) => {
          const dom_citta = getDomCitta(r);
          if (dom_citta.filter((x) => x !== null).length === 0) return null;
          return (
            <Accordion.Item key={r} eventKey={idx}>
              <Accordion.Header>{r}</Accordion.Header>
              <Accordion.Body>
                <div
                  className="row"
                  style={{
                    backgroundColor: 'whitesmoke',
                    border: '1px solid gainsboro',
                    borderRadius: '4px',
                    paddingBottom: '2px'
                  }}>
                  <div className="col-lg" style={{ marginLeft: '1.4rem' }}>
                    Città
                  </div>
                  <div className="col-lg-4 text-center">Periodo</div>
                  <div className="col-lg-2 text-end">Presenze</div>
                  <div className="col-lg-2 text-end">Incassi</div>
                </div>
                {dom_citta}
              </Accordion.Body>
            </Accordion.Item>
          );
        })}
      </Accordion>
      {/* {agenzie.length > 0 && <EsportaDati data={dati} nomeFile="DatiProvince" disabled={true}/>} */}
    </div>
  );
}
