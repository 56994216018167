import React from 'react';
import useFetch from '../../hooks/useFetch';
import { MultiSelect } from 'primereact/multiselect';

export default function FiltroNazioniProduzione({
  onChange,
  disabled = false,
  itemsSelected = []
}) {
  const { data } = useFetch('liste/nazioni/produzione', 'GET', null, !disabled);

  return (
    
    <MultiSelect
      value={itemsSelected}
      options={data}
      onChange={e => onChange(e.value)}
      optionLabel="descrizione"
      optionValue="valore"
      disabled={disabled}
      virtualScrollerOptions={{ itemSize: 40, scrollHeight: '29rem' }}
      filter
      placeholder="Seleziona una o più nazioni di produzione"
      display="chip"
      className="w-100"
    />
  );
}
