import React from 'react';
import Filtro from './Filtro';
import useFetch from '../../hooks/useFetch';
import { MultiSelect } from 'primereact/multiselect';

export default function FiltroAgenzie({
  selectAll,
  onChange,
  maxHeight,
  disabled = false,
  mode = 'table',
  agenzie = null
}) {
  const { data, error } = useFetch('liste/agenzie', 'GET', null, !disabled);

  return mode === 'table' ? (
    <Filtro
      titolo="agenzie"
      dati={data}
      errore={error?.message}
      onChange={onChange}
      selectAll={selectAll}
      maxHeight={maxHeight}
    />
  ) : (
    <MultiSelect
      value={agenzie}
      options={data}
      onChange={(e) => onChange(e.value)}
      optionLabel="descrizione"
      optionValue="valore"
      disabled={disabled}
      virtualScrollerOptions={{ itemSize: 40, scrollHeight: '29rem' }}
      filter
      placeholder="Seleziona uno o più agenzie"
      display="chip"
      className="w-100"
    />
  );
}
