import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useFetch from '../../hooks/useFetch';
import useScrollTop from "../../hooks/useScrollTop";
import FiltroAgenzie from '../filtri/FiltroAgenzie';
import FiltroCircuiti from '../filtri/FiltroCircuiti';
import FiltroDistributoriContabili from '../filtri/FiltroDistributoriContabili';
import Loader from '../Loader';
import GraficoConfronto from './GraficoConfronto';


export default function DettaglioConfrontoPeriodo() {
    const [distributori, setDistributori] = useState([]);
    const [agenzie, setAgenzie] = useState([]);
    const [circuiti, setCircuiti] = useState([]);
    const [dati, setDati] = useState(null);
    let params = useParams();
    const { data } = useFetch(`andamenti/${params.periodo}?show=${params.quanti}`);
    useScrollTop();


    useEffect(() => {
        if(data){
            setDati(data.data)
        }
    }, [data])

    const dati_filtrati = dati && distributori && agenzie && circuiti &&
        dati.andamenti.filter(x =>
            distributori.some(d => d.valore === x.id_distributore) &&
            agenzie.some(d => d.valore === x.id_agenzia) &&
            circuiti.some(d => d.valore === x.id_circuito)
        )

    

    return (
        <div className="container mt-5" style={{ 'marginBottom': '8rem' }}>

            <div className="row mt-5">
                <div className="col-lg-3">
                    <div /*className="sticky-top"*/ style={{ 'top': '1rem' }}>
                        <FiltroDistributoriContabili
                            onChange={setDistributori}
                            maxHeight={'200px'}
                            selectAll={true} />
                        <FiltroAgenzie
                            onChange={setAgenzie}
                            selectAll={true} />
                        <FiltroCircuiti
                            onChange={setCircuiti}
                            maxHeight={'200px'}
                            selectAll={true} />
                    </div>
                </div>
                <div className="col-lg pannello p-4">
                    {/* <h2 className="text-center mb-5">Incassi per {params.periodo}</h2> */}
                    {dati_filtrati && dati_filtrati.length > 0 && (
                        <GraficoConfronto
                            dati={dati_filtrati}
                            periodi={dati.periodi.sort((a, b) => a.posizione - b.posizione)}
                            tipo_periodo={params.periodo}
                            distr_selezionati={distributori}
                            agenzie_selezionate={agenzie}
                            circuiti_selezionati={circuiti}
                        />
                    )}
                    {dati_filtrati && dati_filtrati.length === 0 && (
                        <div className="text-center" style={{ 'fontSize': '1.4rem' }}>
                            Nessun dato da mostrare<br />
                            Cambiare i filtri per trovare dei risultati
                        </div>
                    )}
                    {!dati_filtrati && <Loader messaggio="Caricamento dati in corso..." />}
                </div>
            </div>
        </div>
    )
}