import { useEffect, useState } from 'react';
import fetcher from '../api/fetcher';

export const StatiRichiesta = {
  Idle: 'idle',
  Loading: 'loading',
  Error: 'error',
  Success: 'success'
};

export default function useFetch(url, method = 'GET', body = null, canRequest = true) {
  const [data, setData] = useState();
  const [error, setError] = useState(null);
  const [status, setStatus] = useState(StatiRichiesta.Idle);

  useEffect(() => {
    if (!canRequest || !url) {
      return;
    }
    
    let doUpdate = true;
    setStatus(StatiRichiesta.Loading);
    setData(undefined);
    setError(null);

    if (url) {
      fetcher({
        url: url,
        method: method,
        data: body ? JSON.stringify(body) : null,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(({data}) => {
          if (doUpdate) {
            setData(data);
            setStatus(StatiRichiesta.Success);
          }
        })
        .catch(({response}) => {
          if (doUpdate) {
            console.warn(response.data)
            setError(response.data);
            setStatus(StatiRichiesta.Error);
          }
        });
    } else {
      if (doUpdate) {
        setError(null);
        setStatus(StatiRichiesta.Idle);
      }
    }
    return () => (doUpdate = false);
  }, [url, canRequest, body, method]);

  return { data, setData, status, error };
}
