import React, { Fragment, useState, useEffect } from 'react';
import { printData, updateUserPreferences } from '../../common/utils';
import useScrollTop from '../../hooks/useScrollTop';
import ConfrontoPeriodo from './ConfrontoPeriodo';
import Offcanvas from 'react-bootstrap/Offcanvas';
import ClassificaCinemaNew from '../classifiche/ClassificaCinemaNew';
import UltimaClassificaFilms from '../classifiche/UltimaClassificaFilms';
import Mappa from '../Mappa';
import HeaderTitoli from '../HeaderTitoli';
import useFetch from '../../hooks/useFetch';
import FiltroDistributoriContabili from '../filtri/FiltroDistributoriContabili';

const oggi = new Date();
const ieri = new Date(oggi.setDate(oggi.getDate() - 1));

export default function HomePage() {
  const { data: filters } = useFetch(`/filters`);
  const { data: info } = useFetch(`/status`);
  const [showDistrib, setShowDistrib] = useState(false);
  const [nPeriodi, setNPeriodi] = useState({});
  const [switchesIncassiPresenze, setSwitchesIncassiPresenze] = useState({});
  const [distrib, setDistrib] = useState([]);
  useScrollTop();

  useEffect(() => {
    const users_pref_ls = localStorage.getItem('user_config');
    const {
      homepage_distribuzioni,
      homepage_numero_periodi_anno,
      homepage_numero_periodi_mese,
      homepage_numero_periodi_giorno,
      cinema_switch_incassi_presenze,
      film_switch_incassi_presenze
    } = JSON.parse(users_pref_ls ?? '{}');
    setDistrib(homepage_distribuzioni ?? []);
    setNPeriodi({
      ...nPeriodi,
      homepage_numero_periodi_anno: homepage_numero_periodi_anno ?? 3,
      homepage_numero_periodi_mese: homepage_numero_periodi_mese ?? 3,
      homepage_numero_periodi_giorno: homepage_numero_periodi_giorno ?? 3
    });
    setSwitchesIncassiPresenze({
      ...switchesIncassiPresenze,
      cinema_switch_incassi_presenze: cinema_switch_incassi_presenze ?? 'incassi',
      film_switch_incassi_presenze: film_switch_incassi_presenze ?? 'incassi'
    });
  }, []);

  useEffect(() => {
    if (!filters?.users_pref) {
      return;
    }

    localStorage.setItem('user_config', filters.users_pref);
    const {
      homepage_distribuzioni,
      homepage_numero_periodi_anno,
      homepage_numero_periodi_mese,
      homepage_numero_periodi_giorno,
      cinema_switch_incassi_presenze,
      film_switch_incassi_presenze
    } = JSON.parse(filters.users_pref);
    setDistrib(homepage_distribuzioni ?? []);
    setNPeriodi({
      ...nPeriodi,
      homepage_numero_periodi_anno: homepage_numero_periodi_anno ?? 3,
      homepage_numero_periodi_mese: homepage_numero_periodi_mese ?? 3,
      homepage_numero_periodi_giorno: homepage_numero_periodi_giorno ?? 3
    });
    setSwitchesIncassiPresenze({
      ...switchesIncassiPresenze,
      cinema_switch_incassi_presenze: cinema_switch_incassi_presenze ?? 'incassi',
      film_switch_incassi_presenze: film_switch_incassi_presenze ?? 'incassi'
    });
  }, [filters]);

  const handleSetDistrib = (items) => {
    setDistrib([...items]);
    const users_pref_ls = localStorage.getItem('user_config');
    let users_pref = JSON.parse(users_pref_ls ?? '{}');
    users_pref.homepage_distribuzioni = [...items];
    localStorage.setItem('user_config', JSON.stringify(users_pref));
    updateUserPreferences(users_pref);
  };

  const is_fake = ['test', 'development'].includes(process.env.REACT_APP_MODE);

  return (
    <Fragment>
      <div className="mt-5 text-center" style={{ marginBottom: '6rem' }}>
        <HeaderTitoli />
        <h2>Situazione Riepilogativa al {printData(ieri)}</h2>
        <p className="mb-5" style={{ fontSize: '1.2rem' }}>
          {info ? `Ultimi dati disponibili: ${printData(new Date(info.data.last_data))}` : ' '}
        </p>
      </div>
      <div
        className={(is_fake ? 'container-fluid' : 'container') + ' mt-5'}
        style={{ maxWidth: is_fake ? '' : '1100px' }}>
        <div className="row align-content-center justify-content-center">
          <div className={is_fake ? 'col-xl-4' : 'col-lg-6'}>
            {switchesIncassiPresenze && switchesIncassiPresenze.cinema_switch_incassi_presenze && (
              <ClassificaCinemaNew
                tipoDefault={switchesIncassiPresenze?.cinema_switch_incassi_presenze}
              />
            )}
          </div>
          {is_fake && (
            <div className="col-xl-4 px-4 pt-4">
              <Mappa />
            </div>
          )}
          <div className={is_fake ? 'col-xl-4' : 'col-lg-6'}>
            {switchesIncassiPresenze && switchesIncassiPresenze.film_switch_incassi_presenze && (
              <UltimaClassificaFilms
                tipoDefault={switchesIncassiPresenze?.film_switch_incassi_presenze}
              />
            )}
          </div>
        </div>
      </div>
      <div className="container mt-5" style={{ marginBottom: '8rem', maxWidth: '1100px' }}>
        {nPeriodi && nPeriodi.homepage_numero_periodi_giorno && (
          <ConfrontoPeriodo
            titolo="Confronto per giorno"
            periodo="giorno"
            distributori={distrib}
            nPeriodi={nPeriodi?.homepage_numero_periodi_giorno}
          />
        )}
        {nPeriodi && nPeriodi.homepage_numero_periodi_mese && (
          <ConfrontoPeriodo
            titolo="Confronto per mese"
            periodo="mese"
            distributori={distrib}
            nPeriodi={nPeriodi?.homepage_numero_periodi_mese}
          />
        )}
        {nPeriodi && nPeriodi.homepage_numero_periodi_anno && (
          <ConfrontoPeriodo
            titolo="Confronto per anno"
            periodo="anno"
            distributori={distrib}
            nPeriodi={nPeriodi?.homepage_numero_periodi_anno}
          />
        )}

        <div className="mx-auto mt-5" style={{ maxWidth: '400px' }}>
          <button
            onClick={() => setShowDistrib(true)}
            className="btn btn-lg btn-blue-cinetel bg-gradient w-100">
            Mostra distribuzioni
          </button>
        </div>

        {/* <ConfrontoPeriodo titolo="Confronto stesso giorno (VENERDI)" idtipoperiodo={1} periodo="giorno" distributori={distrib.map(i => i.descrizione)} /> */}

        <Offcanvas show={showDistrib} onHide={() => setShowDistrib(false)} scroll={true}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Seleziona distribuzioni</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <FiltroDistributoriContabili
              // maxHeight={'200px'}
              onChange={(items) => {
                handleSetDistrib(items);
              }}
              titolo="Distribuzioni"
              itemsSelected={distrib}
              selectAll={false}
            />
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </Fragment>
  );
}
