import { useState, useEffect, useCallback } from 'react';
import Loader from '../Loader';
import { BsSearch } from 'react-icons/bs';
import { NomiMesi } from '../../common/utils';

const getDescrizionePeriodo = (p) => {
  let start = new Date(p['iniz_per']);
  let end = new Date(p['fine_per']);
  let start_str =
    ('0' + start.getDate()).slice(-2) +
    '/' +
    ('0' + (start.getMonth() + 1)).slice(-2) +
    '/' +
    start.getFullYear();
  let end_str =
    ('0' + end.getDate()).slice(-2) +
    '/' +
    ('0' + (end.getMonth() + 1)).slice(-2) +
    '/' +
    end.getFullYear();
  if (p['iniz_per'] !== p['fine_per']) return 'dal ' + start_str + ' al ' + end_str;
  return 'del ' + start_str;
};

export default function SelettorePeriodo({ periodi, currentIdperiodo, setIdperiodo, filtro }) {
  const [anno, setAnno] = useState(
    periodi?.length > 0 ? new Date(periodi[0]['iniz_per']).getFullYear() : -1
  );
  const [periodo, setPeriodo] = useState(currentIdperiodo ?? -1);

  const lista_anni =
    periodi && Array.from(new Set(periodi.map((p) => new Date(p['iniz_per']).getFullYear())));
  const lista_periodi_in_anno =
    periodi && periodi.filter((p) => new Date(p['iniz_per']).getFullYear() === anno);

  const setAnnoCombo = useCallback((valore, propaga) => {
    setAnno(valore);

    if (propaga) {
      let periodi_in_anno =
        periodi && periodi.filter((p) => new Date(p['iniz_per']).getFullYear() === valore);
      if (periodi_in_anno && periodi_in_anno.length > 0) {
        setPeriodo(periodi_in_anno[0]['id_periodo']);
      }
    }
  }, [setAnno, periodi]);

  useEffect(() => {
    setPeriodo(currentIdperiodo ?? -1);
    if (periodi) {
      const periodo_selezionato = periodi.find((p) => p.id_periodo === currentIdperiodo);
      if (periodo_selezionato) {
        const anno_selezionato = new Date(periodo_selezionato['iniz_per']).getFullYear();
        setAnnoCombo(anno_selezionato, false);
      }
    }
  }, [currentIdperiodo, periodi, setAnnoCombo]);

  if (lista_anni?.length === 0) return <Loader messaggio="Caricamento dei periodi in corso..." />;

  return (
    <div
      className="row gx-1 text-center mx-auto mb-5 align-items-center justify-content-center"
      style={{ maxWidth: '40rem' }}>
      <div className="col-md-2 mb-1 mb-lg-0">
        <select
          value={anno}
          disabled={!lista_anni}
          onChange={(e) => setAnnoCombo(parseInt(e.target.value), true)}
          className="form-select form w-100"
          style={{ fontWeight: '500', fontSize: '1.1rem' }}>
          {lista_anni &&
            lista_anni.map((anno) => (
              <option value={anno} key={anno}>
                {anno}
              </option>
            ))}
        </select>
      </div>

      {filtro?.has_combo === 1 && (
        <div className="col-md mb-1 mb-lg-0">
          <select
            value={periodo}
            disabled={!lista_periodi_in_anno}
            onChange={(e) => setPeriodo(parseInt(e.target.value))}
            className="form-select w-100"
            style={{ fontWeight: '500', fontSize: '1.1rem' }}>
            {lista_periodi_in_anno &&
              lista_periodi_in_anno.map((p, idx) => {
                let descrizione_periodo = '';
                if (filtro.id !== 4)
                  descrizione_periodo =
                    'Periodo #' +
                    (lista_periodi_in_anno.length - idx) +
                    ' ' +
                    getDescrizionePeriodo(p);
                else descrizione_periodo = NomiMesi[new Date(p['iniz_per']).getMonth()];
                return (
                  <option value={p.id_periodo} key={p.id_periodo}>
                    {descrizione_periodo}
                  </option>
                );
              })}
          </select>
        </div>
      )}
      <div className="col-md-1">
        <button
          onClick={() => setIdperiodo(periodo)}
          disabled={currentIdperiodo === periodo}
          className="btn btn-outline-blue-cinetel w-100">
          <BsSearch />
        </button>
      </div>
    </div>
  );
}
