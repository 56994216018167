import axios from 'axios';
import { toast } from 'react-toastify';

export const baseurl = process.env.REACT_APP_BASE_URL_API;
const url_login = process.env.REACT_APP_URL_LOGIN;

const fetcher = axios.create({
  baseURL: baseurl
});

function AddAuthHeader(config) {
  const token = ['test', 'development'].includes(process.env.REACT_APP_MODE)
    ? process.env.REACT_APP_TOKEN
    : localStorage.getItem(process.env.REACT_APP_STORED_TOKEN);
  if (token) {
    config.headers.Authorization = `bearer ${token}`;
  }
  return config;
}

async function gestioneErrore(error) {
  console.error(error.response);
  if (error.code === 'ERR_NETWORK') {
    toast.error('Nessuna Connessione', { pauseOnHover: false });
  } else if (error.response) {
    if (error.response.status === 401) {
      window.localStorage.removeItem(process.env.REACT_APP_STORED_TOKEN);
      if (process.env.REACT_APP_MODE !== 'test') {
        window.location.href = url_login;
      }
      return Promise.reject(error);
    } 

    if (
      error.request.responseType === 'blob' &&
      error.response.data instanceof Blob &&
      error.response.data.type &&
      error.response.data.type.toLowerCase().indexOf('json') !== -1
    ) {
      const e = JSON.parse(await error.response.data.text());
      toast.error(e.message, { pauseOnHover: false });
      return Promise.reject(error);
    }

    if(error.response.data?.message){
      toast.error(error.response.data.message, { pauseOnHover: false });
      return Promise.reject(error);
    }

    toast.error(error.response.statusText, { pauseOnHover: false });
    return Promise.reject(error);
  }
  return Promise.reject(error);
}

fetcher.interceptors.request.use((req) => {
  req = AddAuthHeader(req);
  return req;
});

fetcher.interceptors.response.use((res) => {
  return res;
}, gestioneErrore);

export default fetcher;
