import React,{ useRef, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Filler,
    Tooltip,
    Legend
    , registerables
} from 'chart.js';
import { colori, printInteroConSeparatoriMigliaia } from '../../common/utils';
import EsportaDati from '../EsportaDati';

ChartJS.register(
    ...registerables,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Filler,
    Title,
    Tooltip,
    Legend
);

const plugins = [{
    beforeDraw: function (chart) {
        const { ctx } = chart;
        ctx.save();
        ctx.globalCompositeOperation = 'destination-over';
        ctx.fillStyle = 'whitesmoke';
        ctx.fillRect(0, 0, chart.width, chart.height);
        ctx.restore();
    }
}]


export default function GraficoAndamenti({ serie, versioni }) {
    const chartRef = useRef();
    const campi = ['incassi', 'presenze', 'cinema'];

    const [campo, setCampo] = useState(campi[0]);
    
    

    const getTotali = (array, campo) => {
        // 
        let totali_periodi = [];
        for (let i = 0; i < array.data.length; i++) {
            let parziali = [];
            const cinema = array.data[i].cinema.filter(x => versioni.indexOf(x.versione) !== -1);
            // 
            // 

            if (campo !== 'cinema') {
                parziali.push(...cinema.map(x => x[campo]));
                // 
                // 
                const somma = parziali.reduce((partialSum, a) => partialSum + a, 0);
                // 
                totali_periodi.push(somma);
            }
            else {
                parziali.push(...cinema.map(x => x.progress_cinema));
                // 
                // 
                const conteggio = Array.from(new Set(parziali)).length;
                // 
                totali_periodi.push(conteggio);
                // 
                // 
            }
        }
        return totali_periodi;
    }

    const getCumulato = (array, campo) => {
        
        
        const dati_periodo = getTotali(array, campo);
        let totale = 0;
        const cumulato = dati_periodo.map(d => {
            totale = totale + d;
            return totale;
        })
        return cumulato
    }

    
    
    const dati_grafico = {
        datasets:
            [
                ...serie.map((data, idx) => {
                    const x_dati = data.data.map(s => s.offset)
                    const y_dati = getCumulato(data, campo)
                    const dati = y_dati.map((y, id) => {
                        return {
                            x: String(x_dati[id]),
                            y: y
                        }
                    })
                    
                    
                    return {
                        type: 'line',
                        label: data.titolo.toUpperCase() + ' cumulato',
                        stack: idx,
                        borderRadius: 4,
                        pointRadius: 3,
                        borderWidth: 2,
                        hoverBackgroundColor: 'yellow',
                        borderColor: colori[idx],
                        backgroundColor: 'white',
                        data: dati,
                        yAxisID: 'y1',
                        barPercentage: 0.90,
                        categoryPercentage: 1.0,
                        maxBarThickness: 100
                    }
                }),
                ...serie.map((data, idx) => {
                    const x_dati = data.data.map(s => s.offset)
                    const y_dati = getTotali(data, campo)
                    const dati = y_dati.map((y, id) => {
                        return {
                            x: String(x_dati[id]),
                            y: y
                        }
                    })
                    return {
                        type: 'bar',
                        fill: false,
                        stack: idx,
                        label: data.titolo.toUpperCase(),
                        borderRadius: 4,
                        // pointRadius: 0,
                        borderColor: colori[idx],
                        backgroundColor: colori[idx],
                        data: dati,
                        barPercentage: 0.90,
                        categoryPercentage: 1.0,
                        maxBarThickness: 100
                    }
                })

            ]
    }
    
    

    const options = {
        responsive: true,
        // indexAxis: 'y',
        interaction: {
            intersect: true,
            mode: 'x',
        },
        scales: {
            y: {
                stacked: true,
                ticks: {
                    color: 'black', font: {
                        size: 14,
                    },
                    callback: function (value, index, ticks) {
                        return campo === 'incassi' ? '€ ' + printInteroConSeparatoriMigliaia(value) : printInteroConSeparatoriMigliaia(value);
                    }
                }
            },
            y1: {
                type: 'linear',
                display: true,
                position: 'right',
                grid: {
                    drawOnChartArea: false,
                },
                ticks: {
                    color: 'black', font: {
                        size: 14,
                    },
                    callback: function (value, index, ticks) {
                        return campo === 'incassi' ? '€ ' + printInteroConSeparatoriMigliaia(value) : printInteroConSeparatoriMigliaia(value);
                    }
                }
            },
            x: {
                stacked: true,
                type: 'linear',
                ticks: {
                    min: 0,
                    color: 'black', font: {
                        size: 12,
                    },
                    beginAtZero: true,
                    callback: function (value) { if (value % 1 === 0) { return (value >= 0 ? '+' : '') + value } } // lascia sulle x solo i valori interi e mette prima il segno +
                }
            }

        },
        plugins: {
            legend: {
                position: 'top'
            },
            title: {
                display: true,
                text: 'Andamento ' + campo,
                color: 'black',
                padding: {
                    top: 10,
                    bottom: 30
                },
                font: {
                    size: 28,
                    weight: '500',
                    family: 'Barlow'
                }
            }
        },
    };

    return (
        <div className="container">
            <div className='row mb-5 p-4'>
                {/* <h2 className="text-center mb-5">Andamenti</h2> */}
                <div className="col-12">
                    {campi && (
                        <div className="row">
                            <div className="col">
                                <select
                                    className='form-select mb-4 d-block mx-auto'
                                    value={campo}
                                    style={{ 'maxWidth': '20rem' }}
                                    onChange={e => setCampo(e.target.value)}>
                                    {
                                        campi.map(c => <option key={c} value={c}>{c.toUpperCase()}</option>)
                                    }
                                </select>
                            </div>
                        </div>
                    )}
                </div>
                <div className="col-12">
                    <Bar
                        ref={chartRef}
                        data={dati_grafico}
                        options={options}
                        plugins={plugins}
                    />
                    {/* <EsportaDati
                        data={serie}
                        nomeFile="AndamentoFilm"
                        disabled={true}
                    /> */}
                </div>

            </div>

        </div>

    )
}