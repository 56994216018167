import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "primereact/resources/themes/bootstrap4-light-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";          
import 'react-pivottable/pivottable.css';  
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import DashboardPage from './components/dashboard/DashboardPage';
import Layout from './components/Layout';
import FilmPage from './components/film/FilmPage';
import HomePage from './components/home/HomePage';
import DettaglioConfrontoPeriodo from './components/home/DettaglioConfrontoPeriodo';
import DettaglioFilmPage from './components/dettagliofilm/DettaglioFilmPage';
import AnalisiPage2 from './components/analisi/AnalisiPage2';
import LoginPage from './components/login/LoginPage';
import RealTimePage from './components/realtime/RealTimePage';
import TabellaRealTimeDettaglioFilm from './components/realtime/TabellaRealTimeDettaglioFilm';
import TabellaRealTimeDettaglioAgenzia from './components/realtime/TabellaRealTimeDettaglioAgenzia';


function App() {

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="boxoffice" element={<DashboardPage />} />
        <Route path="films/:progress_film" element={<FilmPage />} />
        <Route path="login" element={<LoginPage />} />
        <Route path="analisi" element={<AnalisiPage2 />} />
        <Route path="dettaglio" element={<DettaglioFilmPage />} />
        <Route path="realtime" element={<RealTimePage />} />
        <Route path="realtime/films/:progress_film" element={<TabellaRealTimeDettaglioFilm />} />
        <Route path="realtime/agenzie/:prog_agenz" element={<TabellaRealTimeDettaglioAgenzia />} />
        <Route index element={<HomePage />} />
        <Route path="confronti/:periodo/:quanti" element={<DettaglioConfrontoPeriodo />} />
      </Route>
    </Routes>
  )

}

export default App;
