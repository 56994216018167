import fetcher from "../api/fetcher";

export function printData(data, fullyear = false) {
    let anno_str = '';
    if (fullyear)
        anno_str = data.getFullYear();
    else
        anno_str = String(data.getFullYear()).slice(-2);

    return ("0" + data.getDate()).slice(-2) + '/' + ("0" + (data.getMonth() + 1)).slice(-2) + '/' + anno_str
}

export function printNumeroCompatto(valore) {
    if (valore < 1000000)
        return parseFloat(parseFloat(String(valore)).toFixed(0)).toLocaleString();
    return parseFloat(String(valore / 1000000)).toFixed(2) + 'M';
}

export function printInteroConSeparatoriMigliaia(valore) {
    var parts = valore.toString().split(".");
    const numberPart = parts[0];
    const thousands = /\B(?=(\d{3})+(?!\d))/g;
    const parteIntera = numberPart.replace(thousands, ".");
    return parteIntera
}

export function printInteroConSeparatoriMigliaiaRealTime(valore) {
    var parts = valore.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return parts[0];
}

export function downloadImageGrafico(refChart, nomeGrafico) {
    var a = document.createElement('a');
    a.href = refChart.current.toBase64Image('image/jpeg', 1);
    a.download = nomeGrafico + '.jpg';

    // Trigger the download
    a.click();
}

export function getSetUnico(mioarray, chiave) {
    return Array.from(new Set(mioarray.map(x => x[chiave])))
}

export function printTime(data) {
    const hh = data.getHours();
    const mm = data.getMinutes();
    const ss = data.getSeconds();
    const timeStr = ("0" + hh).slice(-2) + ':' + ("0" + mm).slice(-2) + ':' + ("0" + ss).slice(-2);
    return timeStr;
}

export const colori = [
    'rgb(220, 20, 60)',
    'rgb(34, 139, 34)',
    'rgb(0, 0, 204)',
    'rgb(255, 165, 0)',
    'rgb(138, 43, 226)',
    'rgb(47, 79, 79)',
    'rgb(255, 20, 147)',
    'rgb(111, 101, 101)',
    'rgb(128, 128, 0)',
    'rgb(46, 45, 45)'
]

export const colori_blu = {
    '2D': 'rgb(0, 109, 151)',
    '2D IMAX': 'rgb(255, 165, 0)',
    '3D': 'cadetblue',
    '3D IMAX': 'rgb(34, 139, 34)'
};

export const NomiMesi = ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno",
    "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"
];

export const NomiGiorni = ["Lunedì", "Martedì", "Mercoledì", "Giovedì", "Venerdì", "Sabato",
    "Domenica"];

export const NomiGiorniUS = ["Domenica", "Lunedì", "Martedì", "Mercoledì", "Giovedì", "Venerdì", "Sabato",
];

export const updateUserPreferences = async (body) => {
    await fetcher({
      url: '/filters',
      method: 'PUT',
      data: body,
      headers: {
        'Content-Type': 'application/json'
      }
    });
  };