import { useState } from 'react';
import { useEffect } from 'react';
import fetcher from '../../api/fetcher';
import Loader from '../Loader';

var classNames = require('classnames');

export default function PanelFiltri({ filtro, setFiltro }) {
  const [filtri, setFiltri] = useState();

  useEffect(() => {
    let doUpdate = true;
    fetcher(`/tipiperiodo`)
      .then(({data}) => {
        if (doUpdate) {
          data.data.push({
            id: 0,
            nome: 'dal/al',
            has_combo: 0
          });
          setFiltri(data.data);
        }
      })
      .catch((error) => {
        if (doUpdate) console.warn('erroreeeeeeee');
      });
    return () => (doUpdate = false);
  }, []);

  useEffect(() => {
    if (filtro == null && filtri) {
      setFiltro(filtri[0]);
    }
  }, [filtri, filtro, setFiltro]);

  if (!filtri) return <Loader messaggio={'Caricamento tipi periodo'} />;

  const tasti = filtri
    .filter((f) => f.nome.toLowerCase() !== 'openings')
    .map((f) => {
      let classi = classNames({
        active: filtro?.id === f.id,
        'col tasto-periodo py-2 py-lg-3 px-2': true
      });
      return (
        <div key={f.nome} className={classi} onClick={() => setFiltro(f)}>
          {f.nome.replace('settimana ', 'sett. ')}
        </div>
      );
    });

  return (
    <div className="row row-cols-auto justify-content-center align-items-center mb-3 mt-4">
      {tasti}
    </div>
  );
}
