import { Fragment } from "react";
import { printData, printNumeroCompatto, printInteroConSeparatoriMigliaiaRealTime } from "../../common/utils";
import { useState } from "react";
import { BsPlusSquare, BsDashSquareFill } from 'react-icons/bs';

export default function ItemProvincia({ dati, dati_provincia }) {
    const [show, setShow] = useState(false);
    function getDomCinema() {
        const cinema_provincia = Array.from(new Set(dati_provincia.map(x => x.progress_cinema)));
        const dati_tutti_cinema = cinema_provincia.map(x=> {
            const dati_cinema = dati_provincia.filter(d => d.progress_cinema === x)
            if (dati_cinema.length === 0)
                return null;
            const incassi = dati_cinema.reduce((partialSum, a) => partialSum + a.incassi, 0);
            const presenze = dati_cinema.reduce((partialSum, a) => partialSum + a.presenze, 0);
            return {
                progress_cinema: dati_cinema[0].progress_cinema,
                nome_cinema: dati_cinema[0].nome_cinema,
                numero_sale: dati_cinema[0].numero_sale,
                incassi: incassi,
                presenze: presenze
            };
        })
        dati_tutti_cinema.sort((a, b) => b['incassi'] - a['incassi']);
        return dati_tutti_cinema.map((x, idx) => {
            return (
                <div key={x.progress_cinema} className="row" style={{ backgroundColor: idx % 2 === 0 ? 'whitesmoke' : 'white', padding: '2px 0' }}>
                    <div className="col" style={{ marginLeft: '1.25rem' }}>
                        {idx+1}) {x.nome_cinema} - {x.numero_sale} sal{x.numero_sale !== 1 ? 'e' : 'a'}
                    </div>
                    <div className="col-2 text-end">
                        {printInteroConSeparatoriMigliaiaRealTime(x.presenze)}
                    </div>
                    <div className="col-2 text-end">
                        &euro; {printInteroConSeparatoriMigliaiaRealTime(x.incassi)}
                    </div>
                </div>
            )
        })
    }

    return (
        <Fragment>
            <div className="row py-1" onClick={() => setShow(val => !val)} style={{ cursor: 'pointer', backgroundColor: show ? 'rgb(0, 109, 151)' : 'white', color: show ? 'white' : 'black' }}>
                <div className="col-lg">
                    {show ? <BsDashSquareFill className="position-relative me-1" style={{ top: '-2px', color: 'white' }} /> : <BsPlusSquare className="position-relative me-1" style={{ top: '-2px' }} />} {dati.citta}
                </div>
                <div className="col-lg-4 text-center">
                    dal {printData(new Date(dati.inizio))} al {printData(new Date(dati.fine))}
                </div>
                <div className="col-lg-2 text-end">
                    {printInteroConSeparatoriMigliaiaRealTime(dati.presenze)}
                </div>
                <div className="col-lg-2 text-end">
                    &euro; {printInteroConSeparatoriMigliaiaRealTime(dati.incassi)}
                </div>
            </div>

            {show && getDomCinema()}

        </Fragment>
    )
}