import { isNaN } from "lodash-es";
import React, { useState } from "react";
import { getSetUnico, printInteroConSeparatoriMigliaia } from "../../common/utils";
import EsportaDati from "../EsportaDati";
import TortaTop from "./TortaTop";

function totale(array, chiave, chiave_target, chiave_sommata) {
    let tot = array.filter(x => x[chiave] === chiave_target).reduce((partialSum, a) => partialSum + a[chiave_sommata], 0);
    return !isNaN(tot) ? tot : 0;
}


export default function TabellaConfronto({ dati, tipo, periodi, totale_min, totale_max }) {
    const [ordine, setOrdine] = useState('totale');
    let tabella = null;
    
    
    const categorie = getSetUnico(dati, tipo !== 'periodo' ? tipo : 'pos_periodo');
    
    

    let dati_con_totali = categorie && categorie.map(cat => {
        let dato = {}
        dato[tipo] = cat;
        periodi.forEach(per => {
            dato[per.nome_periodo] = {
                'incassi': totale(dati.filter(d => d.pos_periodo === per.posizione), tipo, cat, 'incassi'),
                'presenze': totale(dati.filter(d => d.pos_periodo === per.posizione), tipo, cat, 'presenze'),
            }
        });
        dato['totale_incassi'] = totale(dati, tipo, cat, 'incassi')
        return dato;
    });
    
    

    let dati_raggruppati = dati_con_totali && dati_con_totali.filter(x => {
        return x['totale_incassi'] <= totale_max && x['totale_incassi'] >= totale_min;
    });

    
    
    if (ordine === 'circuito' || ordine === 'agenzia' || ordine === 'distribuzione')
        dati_raggruppati.sort((a, b) => String(a[tipo]).toUpperCase() > String(b[tipo]).toUpperCase() ? 1 : -1);
    else if (ordine === 'totale')
        dati_raggruppati.sort((a, b) => b['totale_incassi'] - a['totale_incassi']);
    else if (ordine.length > 0) {
        
        
        
        dati_raggruppati.sort((a, b) => {
            const key_cercata = ordine.replace('\n', '-');
            const key_b = Object.keys(b).find(x=> x.toLowerCase() === key_cercata);
            const key_a = Object.keys(a).find(x=> x.toLowerCase() === key_cercata);
            return b[key_b]['incassi'] - a[key_a]['incassi'];
        })
    }

    let headers = [];
    headers.push({ label: tipo, key: tipo });
    periodi.forEach(p => headers.push({ label: p.descrizione, key: String(p.valore) }));
    
    headers.push({ label: 'totale', key: 'totale' });

    if (tipo !== 'periodo') {
        tabella = dati_raggruppati && dati_raggruppati.map((cat, idx) => {

            return (
                <div className="row py-1 gx-3" key={cat[tipo]} style={{ 'backgroundColor': idx % 2 === 0 ? 'gainsboro' : 'white', 'borderBottom': '1px solid gainsboro' }}>
                    <div className="col" style={{ 'fontWeight': '500' }}>
                        {cat[tipo].toUpperCase()}
                    </div>
                    {
                        periodi.map(per => {
                            const incasso = cat[per.nome_periodo]['incassi'];
                            const totale_periodo = dati_raggruppati.map(d => d[per.nome_periodo]).reduce((partialSum, a) => partialSum + a.incassi, 0);
                            const percentuale = (((incasso / totale_periodo) + Number.EPSILON) * 100);
                            
                            return (
                                <div key={per.posizione} className="col-lg text-end">
                                    {incasso > 0 ? `${printInteroConSeparatoriMigliaia(incasso)} €` : '-'} 
                                    &nbsp;<small>{`(${!isNaN(percentuale) ? percentuale.toFixed(2) : '0'}%)`}</small>
                                </div>
                            )
                        })
                    }
                    <div className="col-lg text-end">
                        {printInteroConSeparatoriMigliaia(cat['totale_incassi'])} € <br />
                    </div>

                </div>
            );

        })

    }

    if (!tabella)
        return null;

    return (
        <div className="mt-5">
            <p className="text-end mb-2" style={{ color: 'gray', fontStyle: 'italic' }}>
                Le colonne sono ordinabili cliccando sulle intestazioni
            </p>
            <div className="row gx-3" style={{ 'fontWeight': '500', 'backgroundColor': 'rgb(0, 109, 151)', 'color': 'white' }}>
                <div className={"col py-1 hover-header " + (ordine === tipo ? 'active-header' : '')} title="Clicca per ordinare in modo alfabetico" onClick={e => setOrdine(e.target.innerText.toLowerCase())}>
                    {tipo.toUpperCase()}
                </div>
                {
                    periodi.map(per => {
                        return (
                            <div key={per.posizione} title="Clicca per ordinare in modo decrescente i totali del periodo" className={"col-lg hover-header text-end py-1 " + (ordine.replace('\n', '-').toLowerCase() === per.nome_periodo.toLowerCase() ? 'active-header' : '')} onClick={e => setOrdine(e.target.innerText.toLowerCase())} style={{ whiteSpace: 'break-spaces' }}>
                                {per.nome_periodo.replace('-', '\n')}
                            </div>
                        )
                    })
                }
                <div className={"col-lg hover-header text-end py-1 " + (ordine === 'totale' ? 'active-header' : '')} title="Clicca per ordinare in modo decrescente i totali" onClick={e => setOrdine(e.target.innerText.toLowerCase())}>
                    TOTALE
                </div>
            </div>
            {tabella}
            <div className="row gx-3 align-items-center" style={{ 'fontWeight': '500', 'backgroundColor': 'rgb(0, 109, 151)', 'color': 'white' }}>
                <div className="col py-1">
                    TOTALI
                </div>
                {
                    periodi.map(per => {
                        return (
                            <div key={per.posizione} className="col-lg text-end py-1">
                                {printInteroConSeparatoriMigliaia(dati_raggruppati.map(d => d[per.nome_periodo]).reduce((partialSum, a) => partialSum + a.incassi, 0))} €
                            </div>
                        )
                    })
                }
                <div className="col-lg text-end py-1">
                    {printInteroConSeparatoriMigliaia(dati_raggruppati.reduce((partialSum, a) => partialSum + a.totale_incassi, 0))} €
                </div>
            </div>
            <div className="row gx-3">
                <div className="col text-end px-0">
                    {/* <EsportaDati
                        data={dati_raggruppati}
                        nomeFile="ConfrontoPeriodi"
                        disabled={true}
                    /> */}
                    {/* <CSVLink
                        data={dati_csv}
                        filename={"incassi.csv"}
                        headers={headers}
                        className="btn btn-outline-blue-cinetel mt-3"
                        target="_blank">Esporta dati</CSVLink> */}
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <TortaTop
                        dati={dati_con_totali}
                        oggetto={tipo}
                    />
                </div>
            </div>
        </div>
    )
}