import { Fragment, useEffect } from "react";
import { useState } from "react";
import Loader from "../Loader";
import Form from "react-bootstrap/Form";
import {
  printData,
  printInteroConSeparatoriMigliaia,
  updateUserPreferences,
} from "../../common/utils";
import ItemClassificaNew from "../classifiche/ItemClassificaNew";
import fetcher from "../../api/fetcher";
import HeaderClassificaNew from "../classifiche/HeaderClassificaNew";
import EsportaDati from "../EsportaDati";

const MAX_LEVELS = 3;

export default function UltimaClassificaFilms({ tipoDefault= 'incassi'}) {
  const [livello, setLivello] = useState(0);
  const [livelli, setLivelli] = useState([
    {
      url: "/classifiche/films",
      header: "Seleziona un film",
    },
  ]);
  const [tipo, setTipo] = useState(tipoDefault);
  const [periodo, setPeriodo] = useState();
  const [data, setData] = useState();

  const aumentaLivello = (id, header = null) => {
    
    
    let copia_livelli = [...livelli];
    if (copia_livelli.length < MAX_LEVELS) copia_livelli.push({});
    switch (livello) {
      case 0:
        copia_livelli[1].url = `/classifiche/${periodo.id_periodo}/films/${id}/cinema`;
        copia_livelli[1].header = header;
        break;
      case 1:
        copia_livelli[2].url = `/classifiche/${periodo.id_periodo}/cinema/${id}/films`;
        copia_livelli[2].header = header;
        break;
      default:
        break;
    }
    setLivelli(copia_livelli);
    setLivello((liv) => liv + 1);
  };

  useEffect(() => {
    const users_pref_ls = localStorage.getItem('user_config');
    let users_pref = JSON.parse(users_pref_ls ?? '{}');
    users_pref[`film_switch_incassi_presenze`] = tipo;
    localStorage.setItem('user_config', JSON.stringify(users_pref));
    updateUserPreferences(users_pref);
  }, [tipo])

  useEffect(() => {
    
    if (livelli) {
      const url = livelli[livello].url;
      
      setData([]);
      if (url !== "") {
        let doUpdate = true;
        fetcher(url)
          .then((result) => {
            if (doUpdate) {
              if (livello === 0) setPeriodo(result.data.periodo);
              setData(result.data.data);
            }
          })
          .catch((error) => {
            if (doUpdate) console.warn("erroreeeeeeee");
          });
        return () => (doUpdate = false);
      }
    }
  }, [livello, livelli]);

  if (!data || !periodo)
    return <Loader messaggio="Caricamento della classifica in corso..." />;

  const totale = data.reduce((partialSum, a) => partialSum + a[tipo], 0);
  data.sort((a, b) => b[tipo] - a[tipo]);

  const classifica = data.map((c, idx) => {
    const percentuale = (c[tipo] / totale) * 100;
    return (
      <ItemClassificaNew
        key={c.id}
        id={c.id}
        posizione={idx + 1}
        titolo={`${c.descrizione} ${
          !!c.sale ? ('(' + c.sale + ' ' + (c.sale > 1 ? 'sale' : 'sala') + ')') : ''
        }`}
        sottotitolo={
          c.sottotitolo
            ? c.sottotitolo.toLowerCase().replace(/\b[a-z]/g, (x) => x.toUpperCase())
            : null
        }
        valore={
          tipo === "incassi"
            ? "€ " + printInteroConSeparatoriMigliaia(c.incassi)
            : printInteroConSeparatoriMigliaia(c.presenze)
        }
        percentuale={percentuale}
        link={c?.link}
        click={
          livello < MAX_LEVELS - 1
            ? () => aumentaLivello(c.id, c.descrizione)
            : null
        }
      />
    );
  });

  return (
    <div>
      <h3 className="text-center mb-2">
        {tipo === "incassi" ? "Incassi" : "Presenze"} film del{" "}
        {printData(new Date(periodo.iniz_per))}
      </h3>
      <div className="d-flex justify-content-center mb-3">
        <Form.Check
          type="switch"
          checked={tipo === "incassi"}
          onChange={() => setTipo((val) => (val === 'incassi' ? 'presenze' : 'incassi'))}
          label={tipo === "incassi" ? "Mostra presenze" : "Mostra incassi"}
        />
      </div>
      <div
        className="scollabile"
        style={{
          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
          borderRadius: "6px",
          maxHeight: "30.6rem",
          minHeight: "30.6rem",
          overflowY: "auto",
        }}
      >
        <HeaderClassificaNew
          testo={livelli[livello]?.header}
          click={
            livello < livelli.length && livello > 0
              ? () => setLivello((l) => l - 1)
              : null
          }
        />
        <div>
          {data?.length > 0 ? (
            <Fragment>{classifica}</Fragment>
          ) : (
            <Loader messaggio="Caricamento in corso..." />
          )}
        </div>
      </div>
      {data?.length > 0 && (
        <EsportaDati url={`${livelli[livello].url}?sortby=${tipo}`} />
      )}
    </div>
  );
}
