import React, { Fragment, useState, useRef, useCallback } from 'react';
import useScrollTop from "../../hooks/useScrollTop";
import FiltroFilm from '../filtri/FiltroFilm';
import FiltroTipiPeriodo from '../filtri/FiltroTipiPeriodo';
import { BsFillFunnelFill } from 'react-icons/bs';
import AnalisiAndamenti from './AnalisiAndamenti';
import HeaderTitoli from '../HeaderTitoli';

const MAX_FILMS_IN_DETTAGLIO = 6;

export default function DettaglioFilmPage() {
    const [tipoperiodoSelezionato, setTipoperiodoSelezionato] = useState(0);
    const [filmSelezionati, setFilmSelezionati] = useState([]);
    const [maxPeriodiSelezionato, setMaxPeriodiSelezionato] = useState(0);
    const [minDataSelezionata, setMinDataSelezionata] = useState('');
    const [maxDataSelezionata, setMaxDataSelezionata] = useState('');

    const [showMaxPeriodi, setShowMaxPeriodi] = useState(false);
    const [showMinData, setShowMinData] = useState(false);
    const [showMaxData, setShowMaxData] = useState(false);

    const [tipoperiodo, setTipoperiodo] = useState(-1);
    const [film, setFilm] = useState([]);
    const [maxPeriodi, setMaxPeriodi] = useState(0);
    const [minData, setMinData] = useState('');
    const [maxData, setMaxData] = useState('');

    const [showMore, setShowMore] = useState(false);
    const domRisultati = useRef();
    useScrollTop();

    const numFilmSelezionati = filmSelezionati.length;
    const invalidAnalisi =
        numFilmSelezionati === 0 ||
        numFilmSelezionati > MAX_FILMS_IN_DETTAGLIO;

    const startAnalisi = useCallback(() => {
        setTipoperiodo(tipoperiodoSelezionato);
        setFilm(filmSelezionati);
        setMaxPeriodi(showMaxPeriodi ? maxPeriodiSelezionato : -1);
        setMinData(showMinData ? minDataSelezionata : '');
        setMaxData(showMaxData ? maxDataSelezionata : '');
        
        domRisultati.current.scrollIntoView()
    }, [
        tipoperiodoSelezionato,
        filmSelezionati,
        maxPeriodiSelezionato,
        showMaxPeriodi,
        showMinData,
        minDataSelezionata,
        showMaxData,
        maxDataSelezionata
    ]);
    

    const resetFiltriAvanzati = () => {
        setShowMaxPeriodi(false);
        setMaxPeriodiSelezionato(1);
        setShowMore(false);
        setShowMinData(false);
        setShowMaxData(false);
        setMinDataSelezionata('');
        setMaxDataSelezionata('');
    }

    return (
        <div style={{ 'marginBottom': '8rem' }}>
            <div className="container pannello my-5 mx-auto" style={{ 'maxWidth': '1000px' }}>

                <div className='row mb-5 p-4'>
                    <HeaderTitoli />
                    <h2 className="text-center mb-5">Confronto tra film</h2>
                    <div className="col-12">
                        <ol>
                            <li>Seleziona uno o più film</li>
                            <li>Seleziona il periodo su cui effettuare l'analisi</li>
                            {/* <li>Seleziona uno o più campi da mostrare nell'analisi</li> */}
                            <li>Premi il pulsante AVVIA ANALISI per recuperare i risultati</li>
                        </ol>
                    </div>
                    <div className="col-lg">
                        <FiltroFilm
                            onChange={setFilmSelezionati}
                            maxHeight={'239px'}
                            selectAll={false} />
                    </div>
                    <div className="col-lg-4">
                        <FiltroTipiPeriodo
                            onChange={setTipoperiodoSelezionato}
                            showRicerca={false}
                            selectAll={false} />
                    </div>
                    <div className="col-12">
                        {!showMore && (
                            <button
                                className='btn btn-lg btn-blue-cinetel mt-3 mb-4 w-100'
                                onClick={() => setShowMore(true)}>
                                <BsFillFunnelFill className='position-relative me-2' style={{ 'top': '-1px' }} />
                                Filtri avanzati
                            </button>
                        )}
                        {showMore && (
                            <Fragment>
                                <div className='row align-items-center'>
                                    <div className="col-8">
                                        <label>
                                            <input
                                                type="checkbox"
                                                className='form-check-input me-2'
                                                checked={showMaxPeriodi}
                                                onChange={e => setShowMaxPeriodi(val => !val)}
                                            />
                                            Limita il numero di periodi da analizzare
                                        </label>
                                    </div>
                                    <div className="col">
                                        <input
                                            type="number"
                                            className='form-control mt-1'
                                            value={maxPeriodiSelezionato}
                                            min={1}
                                            style={{ 'visibility': showMaxPeriodi ? 'visible' : 'hidden' }}
                                            onChange={e => setMaxPeriodiSelezionato(parseInt(e.target.value))}
                                        />
                                    </div>
                                </div>
                                <div className='row align-items-center'>
                                    <div className="col-8">
                                        <label>
                                            <input
                                                type="checkbox"
                                                className='form-check-input me-2'
                                                checked={showMinData}
                                                onChange={e => setShowMinData(val => !val)}
                                            />
                                            Imposta una data minima
                                        </label>
                                    </div>
                                    <div className="col">
                                        <input
                                            type="date"
                                            className='form-control mt-1'
                                            value={minDataSelezionata}
                                            style={{ 'visibility': showMinData ? 'visible' : 'hidden' }}
                                            onChange={e => setMinDataSelezionata(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className='row align-items-center'>
                                    <div className="col-8">
                                        <label>
                                            <input
                                                type="checkbox"
                                                className='form-check-input me-2'
                                                checked={showMaxData}
                                                onChange={e => setShowMaxData(val => !val)}
                                            />
                                            Imposta una data massima
                                        </label>
                                    </div>
                                    <div className="col">
                                        <input
                                            type="date"
                                            className='form-control mt-1'
                                            value={maxDataSelezionata}
                                            style={{ 'visibility': showMaxData ? 'visible' : 'hidden' }}
                                            onChange={e => setMaxDataSelezionata(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <button
                                            className='btn btn-blue-cinetel mt-3 mb-4 w-100'
                                            onClick={resetFiltriAvanzati}>
                                            Annulla filtri avanzati</button>
                                    </div>
                                </div>
                            </Fragment>
                        )}
                    </div>
                    <div className="col-12">
                        <button
                            className='btn btn-lg btn-outline-blue-cinetel mt-3 w-100'
                            disabled={invalidAnalisi}
                            onClick={startAnalisi}>
                            Avvia analisi{numFilmSelezionati > MAX_FILMS_IN_DETTAGLIO ? ' (selezionare max ' + MAX_FILMS_IN_DETTAGLIO + ' film)' : ''}</button>
                    </div>
                </div>
            </div>

            <div ref={domRisultati}>
                <AnalisiAndamenti
                    film={film}
                    tipoperiodo={tipoperiodo}
                    maxperiodi={maxPeriodi}
                    mindata={minData}
                    maxdata={maxData}
                />
            </div>
        </div>
    )
}