import React, { useEffect, useState } from 'react';
import Filtro from './Filtro';
import useFetch from '../../hooks/useFetch';
import { MultiSelect } from 'primereact/multiselect';

export default function FiltroFilm({
  selectAll,
  onChange,
  maxHeight,
  showRicerca,
  disabled = false,
  mode = 'table',
  titoli = null
}) {
  const { data, error } = useFetch('liste/film', 'GET', null, !disabled);
  const [films, setFilms] = useState(null);

  useEffect(() => {
    if (!!data) {
      setFilms(
        data.map((f) => {
          return {
            valore: f.valore,
            descrizione: `${f.titolo.replace(`(${f.titolo_originale ?? ''})`, '')}`,
            dettaglio: `${f.titolo_originale ?? 'TITOLO ORIGINALE ASSENTE'} (${f.prima_program}) incasso totale: ${f.incasso} euro`
          };
        })
      );
    }
  }, [data]);

  return mode === 'table' ? (
    <Filtro
      titolo="film"
      dati={films}
      errore={error?.message}
      onChange={onChange}
      hasRecenti={false}
      showRicerca={showRicerca}
      selectAll={selectAll}
      maxHeight={maxHeight}
    />
  ) : (
    <MultiSelect
      value={titoli}
      options={films}
      onChange={(e) => onChange(e.value)}
      optionLabel="descrizione"
      optionValue="valore"
      disabled={disabled}
      virtualScrollerOptions={{ itemSize: 40, scrollHeight: '29rem' }}
      filter
      placeholder="Seleziona una o più titoli"
      display="chip"
      className="w-100"
    />
  );
}
