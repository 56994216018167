import { Fragment, useEffect, useRef, useState } from 'react';
import { Bar, getElementAtEvent } from 'react-chartjs-2';
import { printData, printInteroConSeparatoriMigliaia } from '../../common/utils.js';
import { StatiRichiesta } from '../../hooks/useFetch';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import Loader from '../Loader';
import EsportaDati from '../EsportaDati';
import fetcher from '../../api/fetcher';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const plugins = [
  {
    beforeDraw: function (chart) {
      const { ctx } = chart;
      ctx.save();
      ctx.globalCompositeOperation = 'destination-over';
      ctx.fillStyle = 'whitesmoke';
      ctx.fillRect(0, 0, chart.width, chart.height);
      ctx.restore();
    }
  }
];

export default function AndamentoPeriodo({
  idperiodo,
  tipo,
  periodo,
  setIdperiodo,
  filtro_selezionato,
  intervalloDate
}) {
  const chartRef = useRef();
  const [url, setUrl] = useState(null);
  const [data, setData] = useState();
  const [status, setStatus] = useState(StatiRichiesta.Idle);
  const [datiGrafico, setDatiGrafico] = useState(null);

  useEffect(() => {
    if (idperiodo != null) {
      setUrl(`/andamenti/${idperiodo}`);
    } else {
      if (filtro_selezionato?.id === 0) {
        setUrl(
          `/andamenti/0?from=${intervalloDate[0]}&to=${intervalloDate[1]}${
            intervalloDate[2] ? '&primaprogram=1' : ''
          }&sortby=${tipo}`
        );
      } else {
        setUrl(null);
      }
    }
  }, [idperiodo, filtro_selezionato, intervalloDate, tipo]);

  useEffect(() => {
    let doUpdate = true;
    setStatus(StatiRichiesta.Loading);
    setData(undefined);

    if (url) {
      fetcher({
        url: url,
        method: 'GET',
        data: null,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(({ data }) => {
          if (doUpdate) {
            setData(data);
            setStatus(StatiRichiesta.Success);
          }
        })
        .catch(({ response }) => {
          if (doUpdate) {
            console.warn(response.data);
            setStatus(StatiRichiesta.Error);
          }
        });
    } else {
      if (doUpdate) {
        setStatus(StatiRichiesta.Idle);
      }
    }
    return () => (doUpdate = false);
  }, [url]);

  useEffect(() => {
    if (!data) {
      return;
    }
    setDatiGrafico({
      labels: data.data.map((x, idx) => {
        if (x['iniz_per'] !== x['fine_per'])
          return printData(new Date(x['iniz_per'])) + '-' + printData(new Date(x['fine_per']));
        return printData(new Date(x['iniz_per']));
      }),
      datasets: [
        {
          label: 'Dati',
          barPercentage: 0.98,
          categoryPercentage: 1.0,
          borderRadius: 4,
          data: data.data.map((x) => x[tipo]),
          maxBarThickness: 100,
          backgroundColor: data.data.map((x) =>
            x['id_periodo'] !== idperiodo ? 'rgb(0, 109, 151)' : 'rgb(255, 102, 0)'
          )
        }
      ]
    });
  }, [data, tipo, idperiodo]);

  let titolo_grafico = '';
  titolo_grafico += tipo === 'incassi' ? 'Incassi' : 'Presenze';
  if (filtro_selezionato) {
    if (filtro_selezionato.id === 0) {
      if (intervalloDate[0] !== intervalloDate[1])
        titolo_grafico += ` dal ${printData(new Date(intervalloDate[0]))} al ${printData(
          new Date(intervalloDate[1])
        )}`;
      else titolo_grafico += ` del ${printData(new Date(intervalloDate[0]))}`;
    } else {
      titolo_grafico += ' per ' + filtro_selezionato.nome;
    }
  }

  const options = {
    responsive: true,
    scales: {
      y: {
        ticks: {
          color: 'black',
          font: {
            size: 16
          },
          callback: function (value, index, ticks) {
            return (tipo === 'incassi' ? '€ ' : '') + printInteroConSeparatoriMigliaia(value);
          }
        }
      },
      x: {
        ticks: {
          color: 'black',
          font: {
            size: 16
          }
        }
      }
    },
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: true,
        text: titolo_grafico,
        color: 'black',
        padding: {
          top: 10,
          bottom: 30
        },
        font: {
          size: 28,
          weight: '500',
          family: 'Barlow'
        }
      }
    }
  };

  if (status === StatiRichiesta.Loading)
    return <Loader messaggio="Aggiornamento del grafico in corso..." />;

  if (data?.data.length === 0) {
    return (
      <div className="text-center my-5 py-5" style={{ minHeight: '30vh' }}>
        <h3>Nessun dato trovato nel periodo selezionato</h3>
      </div>
    );
  }

  const onClick = (event) => {
    if (filtro_selezionato?.id > 0) {
      const info = getElementAtEvent(chartRef.current, event)[0].index;
      const clickedIdPeriodo = data.data[info].id_periodo;
      if (clickedIdPeriodo !== idperiodo) {
        setIdperiodo(clickedIdPeriodo);
      }
    }
  };

  return (
    datiGrafico && (
      <Fragment>
        {/* <h2 style={{ 'textAlign': 'center' }}>
                    <GiMoneyStack /> {tipo === 'incassi' ? 'Incassi' : 'Presenze'}{filtro_selezionato?.id === 0 ? (intervalloDate[0] !== intervalloDate[1] ? ` dal ${printData(new Date(intervalloDate[0]))} al ${printData(new Date(intervalloDate[1]))}` : ` del ${printData(new Date(intervalloDate[0]))}`) : null}{filtro_selezionato?.id !== 0 && ' per ' + filtro_selezionato['nome']}
                </h2> */}
        <div className="mb-5" style={{ maxWidth: '900px', margin: '0 auto' }}>
          <Bar
            ref={chartRef}
            data={datiGrafico}
            onClick={onClick}
            options={options}
            plugins={plugins}
          />
          <EsportaDati url={url} />
        </div>
      </Fragment>
    )
  );
}
