import classNames from 'classnames';
import React, { useState, useEffect } from 'react';
import fetcher from '../../api/fetcher';
import {
  printInteroConSeparatoriMigliaia,
  printInteroConSeparatoriMigliaiaRealTime,
  printTime
} from '../../common/utils';
import useScrollTop from '../../hooks/useScrollTop';
import HeaderTitoli from '../HeaderTitoli';
import TabellaRealTimeBoxOffice from './TabellaRealTimeBoxOffice';
import TabellaRealTimePerAgenzia from './TabellaRealTimePerAgenzia';

const UPDATE_IN_MS = 1000 * 60 * 5;

export default function RealTimePage() {
  const [dati, setDati] = useState(null);
  const [view, setView] = useState({ id: 1, nome: 'Box Office' });
  const [time, setTime] = useState(new Date());
  const views = [
    { id: 1, nome: 'Box Office' },
    { id: 2, nome: 'Dati Per Agenzia' }
  ];

  useScrollTop();

  useEffect(() => {
    getDatiAggiornati();
    const interval = setInterval(() => {
      getDatiAggiornati();
    }, UPDATE_IN_MS);
    return () => clearInterval(interval);
  }, []);

  const getDatiAggiornati = () => {
    fetcher('/realtime')
      .then(({ data }) => {
        data.tamburini.last_dataload = new Date(data.tamburini.last_dataload);
        setDati({ ...data });
      })
      .catch((error) => {
        console.warn('erroreeeeeeee realtime');
      });

    setTime(new Date());
  };

  const tasti = views.map((v) => {
    let classi = classNames({
      active: view?.id === v.id,
      'col tasto-periodo py-2 py-lg-3 px-2': true
    });
    return (
      <div key={v.nome} className={classi} onClick={() => setView(v)}>
        {v.nome}
      </div>
    );
  });

  return (
    <div className="container mt-5" style={{ marginBottom: '8rem', maxWidth: '1100px' }}>
      <div className="row">
        <div className="col-12 text-center">
          <HeaderTitoli />
          <h2 className="mb-5">Real Time</h2>
          <p>
            Il servizio Real Time di Cinetel consente di visualizzare i dati di incasso e presenze
            in tempo reale al momento della raccolta dati stessa permettendo di avere una fotografia
            immediata dell'andamento della giornata cinematografica.
          </p>
          <p>
            Il servizio è attivo dalle ore <b>22:00</b> alle ore <b>6:00</b> del giorno successivo
            con aggiornamenti dei dati ogni <b>5 minuti</b>.
          </p>
          <p>
            Per qualsiasi informazione, chiarimento o segnalazione è disponibile la mail
            <a href="mailto:realtime@cinetel.it">realtime@cinetel.it</a>
          </p>
        </div>
      </div>

      <div className="row mx-auto mt-4 mb-2 pannello py-4" style={{ maxWidth: '900px' }}>
        <div className="col-12 text-center">
          <h4>
            Situazione riepilogativa aggiornata alle {printTime(time)} del{' '}
            {time.toLocaleDateString('it-IT', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit'
            })}
          </h4>
        </div>
        {!dati?.tamburini && (
          <div className="col-12 mt-3 mb-2">
            <h5 className="text-center">Dati non disponibili per la data odierna</h5>
          </div>
        )}
        {dati?.tamburini && (
          <div className="col-12 mt-3 mb-2">
            <div className="row mx-auto text-center text-lg-start" style={{ maxWidth: '400px' }}>
              <div className="col-lg-6">Ultimo dato ricevuto alle:</div>
              <div className="col-lg-6 fw-bold text-center text-lg-end">
                {printTime(dati.tamburini.last_dataload)} del{' '}
                {dati.tamburini.last_dataload.toLocaleDateString('it-IT', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit'
                })}
              </div>
              <div className="col-lg-6">Numero di sale ricevute:</div>
              <div className="col-lg-6 fw-bold text-center text-lg-end">
                {printInteroConSeparatoriMigliaia(dati.tamburini.numsale)}
              </div>
              <div className="col-lg-6">Totale tamburini ricevuti:</div>
              <div className="col-lg-6 fw-bold text-center text-lg-end">
                {printInteroConSeparatoriMigliaia(dati.tamburini.numtamburini)}
              </div>
              <div className="col-lg-6">Totale incasso ricevuto:</div>
              <div className="col-lg-6 fw-bold text-center text-lg-end">
                € {printInteroConSeparatoriMigliaiaRealTime(dati.tamburini.somma_incasso)}
              </div>
              <div className="col-lg-6">Totale presenze ricevuto:</div>
              <div className="col-lg-6 fw-bold text-center text-lg-end">
                {printInteroConSeparatoriMigliaia(dati.tamburini.somma_presenze)}
              </div>
            </div>
          </div>
        )}
        {dati?.tamburini && (
          <div className="col-12">
            <div className="row mt-4 mb-2">
              <div className="col-12 text-center">
                <h4>Previsioni BoxOffice</h4>
              </div>
            </div>
            <div className="row mx-auto" style={{ maxWidth: '400px' }}>
              <div className="col-lg-6 text-center text-lg-start">Totale incasso stimato:</div>
              <div className="col-lg-6 fw-bold text-center text-lg-end">€ {printInteroConSeparatoriMigliaiaRealTime(dati.tamburini.incassi_stimati)}</div>
              <div className="col-lg-6 text-center text-lg-start">Totale presenze stimate:</div>
              <div className="col-lg-6 fw-bold text-center text-lg-end">{printInteroConSeparatoriMigliaia(dati.tamburini.presenze_stimate)}</div>
            </div>
          </div>
        )}
      </div>

      {dati?.boxoffice && dati?.agenzie && (
        <div className="row row-cols-auto justify-content-center align-items-center mb-3 mt-4">
          {tasti}
        </div>
      )}

      {dati?.boxoffice && view.id === 1 && (
        <div className="row mt-4 mb-2">
          <div className="col-12">
            <TabellaRealTimeBoxOffice dati={dati.boxoffice} />
          </div>
        </div>
      )}

      {dati?.agenzie && view.id === 2 && (
        <div className="row mt-4 mb-2">
          <div className="col-12">
            <TabellaRealTimePerAgenzia dati={dati.agenzie} />
          </div>
        </div>
      )}
    </div>
  );
}
