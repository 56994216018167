export default function Paginazione({ pagina, maxVoci, numVoci, onChange }) {

    const numPagine = Math.ceil(numVoci / maxVoci);
    
    
    
    

    if (numPagine <= 1)
        return null;

    const pagine = Array.from({ length: numPagine }, (x, i) => i);

    return (
        <div className="d-flex justify-content-center mb-3">
            {
                pagine.map(p => {
                    return (
                        <div key={p} onClick={() => onChange(p)} className={"btn btn-outline-blue-cinetel py-2 px-0 " + (p === pagina ? ' active' : '')} style={{marginRight: '2px', width: '2rem'}}>
                            {p + 1}
                        </div>
                    )
                })
            }
        </div>
    )



}