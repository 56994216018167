import React, { useState } from "react"


export default function Mappa() {
    const [livello, setLivello] = useState(1);

    const nomeFile = livello === 1 ? 'italia_regioni2.png' : 'lazio.png';

    return (
        <div className="text-center" onClick={() => setLivello(x => x === 1 ? 2 : 1)}>
            <div
                className="mb-3 mx-auto"
                style={{
                    height: '10px',
                    border: '1px solid black',
                    backgroundImage: 'linear-gradient(90deg, cyan, rgb(0,109,151))',
                    maxWidth: '400px',
                    position: 'relative'
                }}
            >
                <span className="position-absolute" style={{ left: '0', top: '-24px' }}>0</span>
                <span className="position-absolute" style={{ left: '50%', top: '-24px', translate: '-50%' }}>PRESENZE</span>
                <span className="position-absolute text-end" style={{ right: '0', top: '-24px' }}>1.000</span>
            </div>
            <p>
                {livello === 1 ? 
                'Seleziona una regione per mostrare i dati delle province':
                'Dati delle province del Lazio'}
            </p>
            <img
                src={require(`../assets/${nomeFile}`)}
                style={{ maxWidth: 'min(500px, 100%)', cursor: 'pointer' }}
                alt=""
            />
        </div>
    )
}