import React from 'react';
import Filtro from './Filtro';
import useFetch from '../../hooks/useFetch'


export default function FiltroTipiPeriodo({ selectAll, onChange, maxHeight, showRicerca, titolo = null }) {
    const {data, error} = useFetch('liste/tipiperiodo');

    return <Filtro
        titolo={titolo? titolo : 'periodi'}
        dati={data}
        errore={error?.message}
        onChange={onChange}
        showRicerca={showRicerca}
        selectAll={selectAll}
        selezioneSingola={true}
        maxHeight={maxHeight} />
}