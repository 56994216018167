import React from 'react';
export default function ModeDisclaimer() {

    if (process.env.REACT_APP_MODE === 'production')
        return null;

    let stile = {
        position: 'fixed',
        bottom: '0.5rem',
        right: '0.5rem',
        color: 'white',
        padding: '0.2rem 0.5rem',
        borderRadius: '5px',
        zIndex: '1000'
    };

    let nome_ambiente = '';
    if (process.env.REACT_APP_MODE === 'test') {
        nome_ambiente = 'test';
        stile['backgroundColor'] = 'rgba(237, 20, 61,0.85)';
    } else if (process.env.REACT_APP_MODE === 'development') {
        nome_ambiente = 'sviluppo';
        stile['backgroundColor'] = 'rgba(34, 139, 34, 0.9)';
    } else if (process.env.REACT_APP_MODE === 'beta') {
        nome_ambiente = 'beta';
        stile['backgroundColor'] = 'rgba(230, 150, 50, 0.9)';
    }

    return (
        <div style={stile}>
            AMBIENTE {nome_ambiente.toUpperCase()}<br/>
            VERSIONE {process.env.REACT_APP_VERSION}
        </div>
    )
}