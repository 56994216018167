import {
  printInteroConSeparatoriMigliaia,
  printInteroConSeparatoriMigliaiaRealTime
} from '../../common/utils';

export default function TabellaRealTimePerAgenzia({dati}) {
  return (
    <table className="mx-auto shadow">
      <thead>
        <tr style={{ color: 'white', backgroundColor: 'rgb(0, 109, 151)' }}>
          <th className="px-3 py-1" style={{ width: '18rem', fontWeight: 'normal' }}>
            Agenzia
          </th>
          <th className="px-3 py-1 text-end" style={{ width: '6rem', fontWeight: 'normal' }}>
            Strutture
          </th>
          <th className="px-3 py-1 text-end" style={{ width: '8rem', fontWeight: 'normal' }}>
            Incasso
          </th>
          <th className="px-3 py-1 text-end" style={{ width: '6rem', fontWeight: 'normal' }}>
            Presenze
          </th>
        </tr>
      </thead>
      <tbody>
        {dati.map((x, idx) => {
          return (
            <tr
              key={x.prog_agenz}
              style={{
                backgroundColor: idx % 2 === 0 ? 'white' : 'whitesmoke'
              }}>
              <td className="px-3 realtime-link"><a href={`/static/realtime/agenzie/${x.prog_agenz}`}>{x.nome_agenz}</a></td>
              <td className="px-3 text-end">{x.contasale}</td>
              <td className="px-3 text-end">
                € {printInteroConSeparatoriMigliaiaRealTime(x.sommaincasso)}
              </td>
              <td className="px-3 text-end">
                {printInteroConSeparatoriMigliaia(x.sommapresenze)}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
