import { useState, useCallback } from 'react';
import { BsSearch } from 'react-icons/bs';

const oggi = new Date();
const ieri = new Date(oggi.setDate(oggi.getDate() - 1));

export default function SelettoreDate({ intervallo, setIntervallo }) {
  const [min, setMin] = useState(intervallo[0]);
  const [max, setMax] = useState(intervallo[1]);
  const [onlyPrima, setOnlyPrima] = useState(intervallo[2]);

  const sanitizeMin = useCallback(() => {
    if (new Date(min) > ieri) {
      setMin(ieri.toISOString().split('T')[0]);
    }
    if (new Date(min) > new Date(max)) {
      setMin(new Date(max).toISOString().split('T')[0]);
    }
  }, [min, max]);

  const sanitizeMax = useCallback(() => {
    if (new Date(max) > ieri) {
      setMax(ieri.toISOString().split('T')[0]);
    }

    if (new Date(max) < new Date(min)) {
      setMax(new Date(min).toISOString().split('T')[0]);
    }
  }, [max, min]);

  const assegnaDate = useCallback(() => {
    setIntervallo([min, max, onlyPrima]);
  }, [min, max, onlyPrima, setIntervallo]);

  return (
    <div
      className="row gx-1 text-center mx-auto mb-5 align-items-center justify-content-center"
      style={{ maxWidth: '40rem' }}>
      <div className="col-md-4 mb-1 mb-lg-0">
        <input
          type="date"
          value={min}
          onBlur={sanitizeMin}
          onChange={(e) => setMin(e.target.value)}
          className="form-control w-100"
          style={{ fontWeight: '500', fontSize: '1.1rem' }}
        />
      </div>

      <div className="col-md-4 mb-1 mb-lg-0">
        <input
          type="date"
          value={max}
          onBlur={sanitizeMax}
          onChange={(e) => setMax(e.target.value)}
          className="form-control w-100"
          style={{ fontWeight: '500', fontSize: '1.1rem' }}
        />
      </div>

      <div className="col-md-1">
        <button onClick={assegnaDate} className="btn btn-outline-blue-cinetel w-100">
          <BsSearch />
        </button>
      </div>
      <label className="mt-2">
        <input
          type="checkbox"
          className="form-check-input me-2"
          checked={onlyPrima}
          onChange={(e) => setOnlyPrima(e.target.checked)}
        />
        Solo film con prima programmazione inclusa nel periodo
      </label>
    </div>
  );
}
