import React from 'react';
import { BsFillExclamationTriangleFill } from 'react-icons/bs'

export default function ErrorMessage({ messaggio }) {

    return (
        <div className="text-center my-5">
            <BsFillExclamationTriangleFill style={{ 'fontSize':'1.8rem'}}/><br />
            Si è verificato un errore... <br />
            <p style={{ 'fontSize': '1.6rem', 'fontStyle': 'italic' }}>
                {messaggio}
            </p>
        </div>
    )

}