import { Fragment, useEffect, useState } from 'react';
import PivotTableUI from 'react-pivottable/PivotTableUI';
import TableRenderers from 'react-pivottable/TableRenderers';
import {
	aggregators,
	numberFormat,
	aggregatorTemplates
} from 'react-pivottable/Utilities';
// import Plot from 'react-plotly.js';
import 'react-pivottable/pivottable.css';
// import createPlotlyRenderers from 'react-pivottable/PlotlyRenderers';

// const PlotlyRenderers = createPlotlyRenderers(Plot);

export default function PivotTabella2({ data }) {
	const [pivotTableUIConfig, setPivotTableUIConfig] = useState({});

	return (
		<Fragment>
			{data.length > 0 && (
				<PivotTableUI
					data={data}
					rows={['distribuzione']}
					cols={['agenzia']}
					vals={['incasso']}
					aggregatorName="Integer Sum"
					aggregators={{
						...aggregators,
						'Integer Sum': aggregatorTemplates.sum(
							numberFormat({ thousandsSep: '.', decimalSep: ',', digitsAfterDecimal: 0 })
						)
					}}
					enableColSort={true}
					rowOrder="value_z_to_a"
					onChange={s => setPivotTableUIConfig(s)}
					renderers={Object.assign({}, TableRenderers)}
					// renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
					{...pivotTableUIConfig}
				/>
			)}
		</Fragment>
	);
}
