import { Fragment, useEffect, useState } from 'react';
import { AiFillSignal } from 'react-icons/ai';
import ItemDato from './ItemDato';
import { useNavigate } from 'react-router-dom';
import useFetch from '../../hooks/useFetch';
import ErrorMessage from '../ErrorMessage';
import Loader from '../Loader';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { updateUserPreferences } from '../../common/utils';

export default function ConfrontoPeriodo({ titolo, distributori, periodo, nPeriodi }) {
  const [numeroPeriodi, setNumeroPeriodi] = useState(nPeriodi);
  const [dati, setDati] = useState(null);
  let navigate = useNavigate();
  const { data, error } = useFetch(`totali/distributori/${periodo}?show=${numeroPeriodi}`);

  useEffect(() => {
    if (data) {
      setDati(data.data);
    }
  }, [data]);

  const handleChangeNumeroPeriodi = (numero) => {
    setNumeroPeriodi(numero)
    const users_pref_ls = localStorage.getItem('user_config');
    let users_pref = JSON.parse(users_pref_ls ?? '{}');
    users_pref[`homepage_numero_periodi_${periodo}`] = numero;
    localStorage.setItem('user_config', JSON.stringify(users_pref));
    updateUserPreferences(users_pref);
  }

  let Body;
  let periodi;

  if (error) {
    Body = (
      <div className="row mb-3 align-items-center">
        <div className="col">
          <ErrorMessage messaggio={error.message} />
        </div>
      </div>
    );
  } else {
    if (!dati) {
      Body = (
        <div className="row mb-3 align-items-center">
          <div className="col">
            <Loader messaggio={'Caricamento totali'} />
          </div>
        </div>
      );
    } else {
      periodi = dati.periodi.sort((a, b) => a.posizione - b.posizione);

      Body = (
        <Fragment>
          <div className="row mx-auto">
            <div className="col-lg align-self-center text-center text-lg-start">Incasso</div>
            {periodi &&
              periodi.map((p, idx) => (
                <ItemDato
                  key={idx}
                  tipo="incassi"
                  distributore={null}
                  ispast={idx > 0}
                  periodo={p}
                  periodo_before={idx == 0 ? null : periodi[0]}
                />
              ))}
          </div>
          {distributori &&
            distributori.map((d) => (
              <div
                key={d.valore}
                className="row mx-auto mt-2"
                style={{ borderTop: '1px solid gainsboro', borderTopStyle: 'dashed' }}>
                <div className="col-lg align-self-center text-center text-lg-start">
                  Incasso <b>{d.descrizione.toUpperCase()}</b>
                </div>
                {periodi &&
                  periodi.map((p, idx) => (
                    <ItemDato
                      key={idx}
                      tipo="incassi"
                      distributore={d}
                      ispast={idx > 0}
                      periodo={p}
                      periodo_before={idx == 0 ? null : periodi[0]}
                    />
                  ))}
              </div>
            ))}

          <div style={{ borderBottom: '1px solid black', margin: '1rem 0' }} />

          <div className="row mx-auto">
            <div className="col-lg align-self-center text-center text-lg-start">Presenze</div>
            {periodi &&
              periodi.map((p, idx) => (
                <ItemDato
                  key={idx}
                  tipo="presenze"
                  distributore={null}
                  ispast={idx > 0}
                  periodo={p}
                  periodo_before={idx == 0 ? null : periodi[0]}
                />
              ))}
          </div>
          {distributori &&
            distributori.map((d) => (
              <div
                key={d.valore}
                className="row mx-auto mt-2"
                style={{ borderTop: '1px solid gainsboro', borderTopStyle: 'dashed' }}>
                <div className="col-lg align-self-center text-center text-lg-start">
                  Presenze <b>{d.descrizione.toUpperCase()}</b>
                </div>
                {periodi &&
                  periodi.map((p, idx) => (
                    <ItemDato
                      key={idx}
                      tipo="presenze"
                      distributore={d}
                      ispast={idx > 0}
                      periodo={p}
                      periodo_before={idx == 0 ? null : periodi[0]}
                    />
                  ))}
              </div>
            ))}
        </Fragment>
      );
    }
  }

  return (
    <div className="mb-4">
      <div className="pannello mx-auto p-3 p-lg-5">
        <div
          className="row mb-3 align-items-center mx-auto text-center text-lg-start"
          style={{ borderBottom: '1px solid black', maxWidth: '1000px' }}>
          <div className="col-lg ps-0" style={{ fontWeight: '500', fontSize: '1.4rem' }}>
            {titolo} {dati?.periodo_comune ? ' (' + dati.periodo_comune + ')' : ''}
          </div>
          {dati && !error && (
            <Fragment>
              <div className="col-lg-auto pe-1">
                <label>Mostra periodi</label>
                <select
                  value={numeroPeriodi}
                  onChange={(e) => handleChangeNumeroPeriodi(e.target.value)}
                  className="form-select d-inline-block ms-1"
                  style={{
                    width: '60px',
                    borderColor: 'black',
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                    position: 'relative',
                    top: '1px'
                  }}>
                  <option>5</option>
                  <option>4</option>
                  <option>3</option>
                  <option>2</option>
                  <option>1</option>
                </select>
              </div>
              <div className="col-lg-auto px-0">
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 300 }}
                  trigger={['hover', 'focus']}
                  overlay={
                    <Tooltip>
                      Analizza gli incassi di questi periodi in base a distributori, agenzie e
                      circuiti
                    </Tooltip>
                  }>
                  <button
                    className="btn btn-outline-dark w-100 position-relative mt-2 mt-lg-0"
                    onClick={() => navigate(`/confronti/${periodo}/${numeroPeriodi}`)}
                    style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0, top: '1px' }}>
                    <AiFillSignal /> Analisi
                  </button>
                </OverlayTrigger>
              </div>
            </Fragment>
          )}
        </div>
        {Body}
      </div>
    </div>
  );
}
