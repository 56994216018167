import React, { useEffect, useState } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
export default function NavbarApp() {
  const location = useLocation();
  const [url, setUrl] = useState(null);
  useEffect(() => {
    setUrl(location.pathname);
  }, [location]);

  return (
    <Navbar
      variant="dark"
      expand="lg"
      className="shadow"
      style={{ overflow: 'hidden'}}>
      <a href={process.env.REACT_APP_URL_LOGIN}><img
        src={require('../assets/cinetel - Copia.png')}
        alt="logo"
        style={{ position: 'absolute', left: '0', top: '0', height: '3.7rem' }}
      /></a>
      <Container>
        <Navbar.Brand href="#home"></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto mt-3 mt-lg-0 me-5">
            <Nav.Link
              as={Link}
              to="/"
              className={url === '/' || url?.startsWith('/confronti/') ? 'nav-attivo' : null}>
              Home
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/boxoffice"
              className={url === '/boxoffice' || url?.startsWith('/films/') ? 'nav-attivo' : null}>
              BoxOffice
            </Nav.Link>
            <Nav.Link as={Link} to="/analisi" className={url === '/analisi' ? 'nav-attivo' : null}>
              Nuova Analisi
            </Nav.Link>
            {/* <Nav.Link
              as={Link} to="/salvataggi" className={url === '/salvataggi' ? 'nav-attivo' : null}
              style={{ cursor: 'not-allowed' }}>
              Analisi Salvate
            </Nav.Link> */}
            <Nav.Link
              as={Link}
              to="/dettaglio"
              className={url === '/dettaglio' ? 'nav-attivo' : null}>
              Confronto Film
            </Nav.Link>
            {['development', 'test'].includes(process.env.REACT_APP_MODE) && (<Nav.Link
              as={Link}
              to="/realtime"
              className={url === '/realtime' ? 'nav-attivo' : null}>
              Real time
            </Nav.Link>)}
          </Nav>
          <Nav>
            <Nav.Link href={process.env.REACT_APP_URL_LOGIN}>Cinetel.it</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
