import React, { useEffect, useState, Fragment } from "react";
import { useParams } from "react-router-dom";
import useFetch, { StatiRichiesta } from "../../hooks/useFetch";
import useScrollTop from "../../hooks/useScrollTop"
import Loader from "../Loader";
import AndamentoFilm from "./AndamentoFilm";
import DatiFilm from "./DatiFilm";
import GraficoRegioni from "./GraficoRegioni";
import TabellaRegioni from "./TabellaRegioni";
import FiltroVersioni from '../filtri/FiltroVersioni';
import UsciteContemporanee2 from "./UsciteContemporanee2";
import Mappa from "../Mappa";
import { printInteroConSeparatoriMigliaia } from "../../common/utils";
import { BsPeopleFill } from "react-icons/bs";
import { GiTwoCoins } from "react-icons/gi";


export default function FilmPage() {
    let params = useParams();
    const [versioniSelezionate, setVersioniSelezionate] = useState(null);
    const [versioniTrovate, setVersioniTrovate] = useState();
    const { data, status } = useFetch(`/films/${params.progress_film}`);
    const { data: data_cinema } = useFetch(`/films/${params.progress_film}/totali`);
    useScrollTop();

    const film = data && data.data;

    useEffect(() => {        
        if (data && data.data.andamento.length > 0) {
            const versioni_trovate = Array.from(new Set(data.data.andamento.map(x => x.versione)));
            setVersioniTrovate(versioni_trovate)
        }
    }, [data])

    if (status === StatiRichiesta.Loading || status === StatiRichiesta.Idle || !film)
        return <Loader messaggio={'Caricamento informazioni film...'} />

    const versioni = versioniSelezionate ? versioniSelezionate.map(x => x.valore) : [];
    return (
        <Fragment>


            <div className="container mt-3">
                <div className="row mb-4">
                    <div className="col text-center mt-2">
                        <h1>{film.titolo}</h1>
                        <h3 style={{fontSize: '1.5rem', fontWeight: 'normal'}}>
                            <GiTwoCoins className="me-1" /> {printInteroConSeparatoriMigliaia(film.andamento.reduce((partialSum, a) => partialSum + a.incassi, 0))} € | <BsPeopleFill className="me-1 position-relative" style={{top: '-2px'}} /> {printInteroConSeparatoriMigliaia(film.andamento.reduce((partialSum, a) => partialSum + a.presenze, 0))}
                        </h3>
                        <p>
                            Regia: {film.regia} | {film.anno_produzione}
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-xl-3">
                        <DatiFilm film={film} />
                    </div>
                    <div className="pannello col p-4">
                        <div className="mx-auto mb-4" style={{ maxWidth: '300px' }}>
                            <FiltroVersioni
                                versioni={versioniTrovate}
                                setVersioniSelezionate={setVersioniSelezionate}
                            />
                        </div>

                        <AndamentoFilm data={film.andamento} versioni={versioni} />
                        {data_cinema && (
                            <Fragment>
                                {
                                    localStorage.getItem('fake') === true && (
                                        <div className="py-5">
                                            <h4 className="mb-5 text-center">Mappa incassi (€)</h4>
                                            <Mappa />
                                        </div>
                                    )
                                }
                                <GraficoRegioni dati={data_cinema.data} versioni={versioni} />
                                <TabellaRegioni dati={data_cinema.data} progress_film={params.progress_film} versioni={versioni} />
                            </Fragment>
                        )}
                        {!data_cinema && (
                            <Loader messaggio={"Caricamento dati regioni e cinema"} />
                        )}
                        <UsciteContemporanee2 dati={film.altri_film_in_data} dataCentrale={film.prima_programmazione} />

                    </div>
                </div>
            </div>

        </Fragment>

    )

}