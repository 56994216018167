import React from 'react';

export default function Footer() {
  return (
    <div
      className="text-center p-5 mt-5 position-relative"
      style={{
        backgroundColor: 'var(--blue-cinetel)',
        fontSize: '14px',
        lineHeight: '1.3',
        color: 'white',
        borderBottom: '3px solid white'
      }}>
      <p>
        Cinetel S.r.l. è una Società partecipata pariteticamente dall'Associazione Nazionale
        Esercenti Cinema (ANEC) e da Anica Servizi S.r.l., che cura quotidianamente la raccolta
        degli incassi e delle presenze in un campione di sale cinematografiche di tutta Italia e li
        ridistribuisce su abbonamento agli operatori del settore.
      </p>
      <hr />
      <p>
        Copyright © 2023 Cinetel. P.IVA 05136321006. Tutti i diritti riservati
        <br />
        Powered by{' '}
        <a
          href="https://www.bittimeprofessionals.it/"
          className="link-bianco"
          style={{ fontWeight: '500' }}>
          bit Time Professionals
        </a>
      </p>
      <p>
        Version {process.env.REACT_APP_VERSION} - built at {process.env.REACT_APP_BUILD_TIMESTAMP}
      </p>
      <img
        src={require('../assets/cinetel_logo.jpg')}
        style={{ position: 'absolute', right: '0', bottom: '-6px', height: '3.7rem' }}
        alt=""
      />
    </div>
  );
}
