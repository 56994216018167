import React, { Fragment } from 'react';
import { useState } from 'react';
import Filtro from '../filtri/Filtro';
import BodyTabella from './BodyTabella';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';

const radios = [
    { name: 'Nascondi valore', value: '0' },
    { name: 'Valore assoluto', value: '1' },
    { name: 'Valore percentuale', value: '2' },
];

export default function TabellaAndamenti({ serie, versioni }) {
    const [tipoCumulato, setTipoCumulato] = useState(0);
    const [campiSelezionati, setCampiSelezionati] = useState([]);
    const [campiTabella] = useState([
        {
            valore: 'incassi',
            descrizione: 'incassi'
        },
        {
            valore: 'presenze',
            descrizione: 'presenze'
        },
        {
            valore: 'cinema',
            descrizione: 'cinema'
        }])


    let dati_tabella = [];
    if (serie && serie.length > 0) {
        const num_films = serie.length;
        

        let offset = [];
        for (let f = 0; f < serie.length; f++) {
            offset.push(...serie[f].data.map(x => x.offset))
        }
        offset = Array.from(new Set(offset)).sort((a, b) => a - b);
        
        

        // per ogni riga, quindi per ogni offset
        for (let r = 0; r < offset.length; r++) {
            // scorre i vari film
            let riga = {
                offset: offset[r],
                films: []
            }

            for (let f = 0; f < num_films; f++) {
                let dati_film = null;
                // verifico se esiste l'offset per il film corrente
                let dati_film_offset = serie[f].data.find(x => x.offset === offset[r]);
                if (dati_film_offset) {
                    const dati_cinema = dati_film_offset.cinema.filter(x => versioni.indexOf(x.versione) !== -1);
                    dati_film = {
                        inizio: dati_film_offset.iniz_per,
                        fine: dati_film_offset.fine_per,
                        incassi: dati_cinema.reduce((partialSum, a) => partialSum + a.incassi, 0),
                        presenze: dati_cinema.reduce((partialSum, a) => partialSum + a.presenze, 0),
                        cinema: (Array.from(new Set(dati_cinema.map(x => x.progress_cinema)))).length,
                        dettagli: dati_cinema
                    }
                }
                riga.films.push(dati_film)
            }
            dati_tabella.push(riga)
        }
        
        
    }

    return (
        <Fragment>
            <h2 className="text-center mb-5">Tabella</h2>
            <div className="mx-auto" style={{ maxWidth: '400px' }}>
                <Filtro
                    titolo='Campi visualizzati'
                    dati={campiTabella}
                    onChange={setCampiSelezionati}
                    selectAll={true}
                    showNumeroVoci={false}
                    selezioneRichiesta={true}
                    showRicerca={false}
                />
            </div>

            {serie && serie.length > 0 && campiSelezionati && campiSelezionati.length > 0 && (
                <div className='text-center'>
                    <div>
                        <p className="mb-2">Dato cumulato</p>
                        <ButtonGroup>
                            {radios.map((radio, idx) => (
                                <ToggleButton
                                    key={idx}
                                    id={`radio-${idx}`}
                                    type="radio"
                                    variant='outline-primary'
                                    name="radio"
                                    value={radio.value}
                                    checked={tipoCumulato === parseInt(radio.value)}
                                    onChange={(e) => setTipoCumulato(parseInt(e.currentTarget.value))}
                                >
                                    {radio.name}
                                </ToggleButton>
                            ))}
                        </ButtonGroup>
                    </div>
                </div>
            )}
            <div className="mx-5 shadow mt-5">
                {serie && serie.length > 0 && campiSelezionati && campiSelezionati.length > 0 && (
                    <Fragment>
                        <BodyTabella
                            dati={dati_tabella}
                            tipoCumulato={tipoCumulato}
                            films={serie.map(x => {
                                return {
                                    titolo: x.titolo,
                                    progress_film: x.progress_film
                                }
                            })}
                            campi={campiSelezionati.map(x => x.valore)}
                        />

                    </Fragment>
                )}
            </div>
        </Fragment>
    )
}