import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Loader from '../Loader';


export default function LoginPage() {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    useEffect(()=> {
        localStorage.setItem(process.env.REACT_APP_STORED_TOKEN, searchParams.get('token'));
        navigate('/');
    }, [searchParams, navigate])

    return <Loader messaggio="Attendere..." />;
}