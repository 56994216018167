import React, { useRef } from 'react';
import { colori, printInteroConSeparatoriMigliaia } from "../../common/utils";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import "chartjs-plugin-doughnut-innertext";
import EsportaDati from "../EsportaDati";

ChartJS.register(ArcElement, Tooltip, Legend);

const plugins = [{
    beforeDraw: function (chart) {
        const { ctx } = chart;
        ctx.save();
        ctx.globalCompositeOperation = 'destination-over';
        ctx.fillStyle = 'white';
        ctx.fillRect(0, 0, chart.width, chart.height);
        ctx.restore();
    }
}]

export default function TortaTop({ dati, oggetto }) {
    const chartRef = useRef();
    const size_classifica = 10;

    const top_items = dati.sort((a, b) => b.totale_incassi - a.totale_incassi).slice(0, size_classifica)

    

    const periodi = Object.keys(top_items[0]).filter(x => x !== 'totale_incassi' && x !== oggetto)

    
    const dati_grafico = {
        labels: top_items.map(x => x[oggetto]),
        datasets: periodi.map(p => {
            return {
                label: p,
                data: top_items.map(x => x[p].incassi),
                backgroundColor: Array.from({ length: size_classifica }, (x, i) => i).map(x => colori[x]),
                borderColor: 'white',
                borderWidth: 3,
                hoverOffset: 0,
                hoverBackgroundColor: 'yellow',
                hoverBorderColor: 'white'
            }
        })
    }

    

    const options = {
        responsive: true,
        // offset: 10,
        centerText: {
            color: "black",
            value: "Periodi più recenti\nall'interno",
            fontSizeAdjust: -0.8 // increase font size 20% based on default font size
        },
        plugins: {
            legend: {
                position: 'left'
            },
            title: {
                display: true,
                text: `Top ${size_classifica} per ${oggetto}`,
                color: 'black',
                padding: {
                    top: 10,
                    bottom: 30
                },
                font: {
                    size: 28,
                    weight: '500',
                    family: 'Barlow'
                }
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        let label = context.label + ': € ' + printInteroConSeparatoriMigliaia(context.parsed) + ', periodo ' + context.dataset.label.toUpperCase();
                        // 
                        // 
                        return label;
                    }
                }
            }
        },
    }

    return (
        <div className="mt-5">
            <div className="row align-items-center">
                <div className="col">
                    {/* <h2 className="text-center">Top {size_classifica} per {oggetto}</h2> */}
                    <Doughnut
                        ref={chartRef}
                        data={dati_grafico}
                        options={options}
                        plugins={plugins}
                    />
                    {/* <EsportaDati
                        data={dati}
                        nomeFile="Top10"
                        disabled={true}
                    /> */}
                </div>
            </div>
        </div>
    )
}