import React, { useState } from "react";
import { NomiGiorni, NomiMesi, printData } from "../../common/utils";
import { BsSearch } from 'react-icons/bs';
import { Link } from "react-router-dom";

export default function UsciteContemporanee2({ dati, dataCentrale }) {
    const data_centrale = new Date(dataCentrale);
    const [data, setData] = useState(data_centrale);

    if (!dati)
        return null;

    var classNames = require('classnames');

    const GIORNI = 13;

    let data_iniziale = new Date(dataCentrale);
    data_iniziale.setDate(data_iniziale.getDate() - Math.floor(GIORNI / 2));

    const film_data_selezionata = dati.filter(f => f.prima_programmazione.slice(0, 10) === data.toISOString().slice(0, 10));
    return (
        <div>
            <h2 className="text-center mb-4 mt-5">Film in uscita nel periodo</h2>
            <div className="d-flex mt-5 mb-5 shadow">
                {
                    Array
                        .from({ length: GIORNI }, (v, i) => i)
                        .map(i => {
                            let data_i = new Date(data_iniziale);
                            data_i.setDate(data_i.getDate() + i);
                            let classi = classNames({
                                'data_centrale': (+data_centrale) === (+data_i),
                                'data_selezionata': (+data) === (+data_i) && (+data_centrale) !== (+data_i),
                                'day-calendar w-100 text-center position-relative py-3': true
                            })
                            const num_films = dati.filter(f => f.prima_programmazione.slice(0, 10) === data_i.toISOString().slice(0, 10)).length;
                            return (
                                <div key={data_i} className={classi} onClick={() => setData(data_i)} style={{ lineHeight: '1.2', cursor: 'pointer' }}>
                                    {num_films > 0 && (
                                        <div className="position-absolute px-1" style={{ fontSize: '0.8rem', top: '0', right: '0', borderBottomLeftRadius: '4px', backgroundColor: 'rgb(50,50,50)', color: 'white' }}>
                                            {num_films}
                                        </div>
                                    )}
                                    <div>
                                        {NomiGiorni[data_i.getDay()].slice(0, 3)}
                                    </div>
                                    <div style={{ fontSize: '1.4rem' }}>
                                        {data_i.getDate()}
                                    </div>
                                    <div style={{ fontSize: '0.9rem', fontStyle: 'italic' }}>
                                        {NomiMesi[data_i.getMonth()].slice(0, 3).toUpperCase()}
                                    </div>
                                </div>
                            )
                        })
                }
            </div>
            <h4 className="mb-4 text-center">
                Film usciti in data {printData(data)}
            </h4>
            {film_data_selezionata.length === 0 && (
                <div className="text-center mb-4">
                    Nessun film da mostrare
                </div>
            )}
            {
                film_data_selezionata.map(f => {
                    return (
                        <div key={f.progress_film}>
                            <p className="mb-0" style={{ 'fontWeight': 'bold', 'fontSize': '1.1rem' }}>
                                <Link to={"/films/" + f.progress_film} className="link-blue" title="Clicca per aprire il link">
                                    <BsSearch style={{ 'position': 'relative', 'top': '-1px' }} /> {f.titolo}
                                </Link>
                            </p>
                            <p>
                                <span className="mb-1" style={{ 'display': 'block', 'fontWeight': '500' }}>
                                    {f.genere} | Regia {f.regia}, {f.durata} min. Cast: {f.attori}
                                </span>
                                <span style={{ display: 'block', fontSize: '0.9rem', lineHeight: '1.4' }}>
                                    {f.descrizione}
                                </span>
                            </p>
                        </div>
                    )
                })
            }
        </div >
    )


}