import React, { Fragment, useState, useEffect, useCallback } from 'react';
import Loader from '../Loader';
import PivotTabella2 from './PivotTabella2';
import HeaderTitoli from '../HeaderTitoli';
import FiltroFilm from '../filtri/FiltroFilm';
import useScrollTop from '../../hooks/useScrollTop';
import FiltroAgenzie from '../filtri/FiltroAgenzie';
import FiltroDistributori from '../filtri/FiltroDistributori';
import FiltroDistributoriContabili from '../filtri/FiltroDistributoriContabili';
import FiltroNazioniProduzione from '../filtri/FiltroNazioniProduzione';
import FiltroCircuiti from '../filtri/FiltroCircuiti';
import FiltroRegioni from '../filtri/FiltroRegioni';
import EsportaDati from '../EsportaDati';
import fetcher from '../../api/fetcher';

const oggi = new Date();
const ieri = new Date(oggi.setDate(oggi.getDate() - 1));

export default function AnalisiPage2() {
  const [messaggio, setMessaggio] = useState(null);
  const [filtro, setFiltro] = useState();
  const [refresh, setRefresh] = useState(0);
  const [dati, setDati] = useState([]);
  const [data, setData] = useState();
  const [formato, setFormato]= useState(); 

  const [showDal, setShowDal] = useState(false);
  const [showAl, setShowAl] = useState(false);
  const [showTitoli, setShowTitoli] = useState(false);
  const [showAnnoProduzione, setShowAnnoProduzione] = useState(false);
  const [showTipologiaFilm, setShowTipologiaFilm] = useState(false);
  const [showPrimaProgrammazione, setShowPrimaProgrammazione] = useState(false);
  const [showDistributori, setShowDistributori] = useState(false);
  const [showDistributoriContabili, setShowDistributoriContabili] = useState(false);
  const [showRegioni, setShowRegioni] = useState(false);
  const [showNazioniProduzione, setShowNazioniProduzione] = useState(false);
  const [showCircuiti, setShowCircuiti] = useState(false);
  const [showNumeroSale, setShowNumeroSale] = useState(false);
  const [showAgenzie, setShowAgenzie] = useState(false);

  const [titoli, setTitoli] = useState([]);
  const [annoProduzione, setAnnoProduzione] = useState(new Date().getFullYear());
  const [dal, setDal] = useState(ieri.toISOString().slice(0, 10));
  const [al, setAl] = useState(ieri.toISOString().slice(0, 10));
  const [tipologiaFilm, setTipologiaFilm] = useState('');
  const [primaProgrammazioneDal, setPrimaProgrammazioneDal] = useState(
    ieri.toISOString().slice(0, 10)
  );
  const [primaProgrammazioneAl, setPrimaProgrammazioneAl] = useState(
    ieri.toISOString().slice(0, 10)
  );
  const [distributori, setDistributori] = useState([]);
  const [distributoriContabili, setDistributoriContabili] = useState([]);
  const [nazioniProduzione, setNazioniProduzione] = useState([]);
  const [regioni, setRegioni] = useState([]);
  const [circuiti, setCircuiti] = useState([]);
  const [filtroNumeroSale, setFiltroNumeroSale] = useState('=');
  const [numeroSale, setNumeroSale] = useState(1);
  const [agenzie, setAgenzie] = useState([]);
  const [loading, setLoading] = useState(false);

  useScrollTop();

  const assegnaDal = useCallback(
    (data) => {
      if (showDal) {
        setDal(data);
      }
    },
    [showDal]
  );

  const assegnaAl = useCallback(
    (data) => {
      if (showAl) {
        setAl(data);
      }
    },
    [showAl]
  );

  const sanitizeDal = useCallback(() => {
    if (new Date(dal) > ieri) {
      setDal(ieri.toISOString().split('T')[0]);
    }
    if (new Date(dal) > new Date(al)) {
      setDal(new Date(al).toISOString().split('T')[0]);
    }
  }, [dal, al]);

  const sanitizeAl = useCallback(() => {
    if (new Date(al) > ieri) {
      setAl(ieri.toISOString().split('T')[0]);
    }

    if (new Date(al) < new Date(dal)) {
      setAl(new Date(dal).toISOString().split('T')[0]);
    }
  }, [al, dal]);

  const sanitizePrimaProgrammazioneDal = useCallback(() => {
    if (new Date(primaProgrammazioneDal) > ieri) {
      setPrimaProgrammazioneDal(ieri.toISOString().split('T')[0]);
    }
    if (new Date(primaProgrammazioneDal) > new Date(primaProgrammazioneAl)) {
      setPrimaProgrammazioneDal(new Date(primaProgrammazioneAl).toISOString().split('T')[0]);
    }
  }, [primaProgrammazioneDal, primaProgrammazioneAl]);

  const sanitizePrimaProgrammazioneAl = useCallback(() => {
    if (new Date(primaProgrammazioneAl) > ieri) {
      setPrimaProgrammazioneAl(ieri.toISOString().split('T')[0]);
    }

    if (new Date(primaProgrammazioneAl) < new Date(primaProgrammazioneDal)) {
      setPrimaProgrammazioneAl(new Date(primaProgrammazioneDal).toISOString().split('T')[0]);
    }
  }, [primaProgrammazioneAl, primaProgrammazioneDal]);

  const avviaAnalisi = (formato = null) => {
    setFormato(formato);
    setMessaggio(null);
    setDati(null);
    setData(null);
    setRefresh((n) => n + 1);
    setLoading(true);

    fetcher({
      url: `incassi/searches?${refresh}` + ((!!formato) ? `&formato=${formato}`: ''),
      method: 'POST',
      data: filtro,
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(({ data }) => {
        setData(data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    let filtro_update = {};
    if (showTitoli && titoli !== '') filtro_update.titoli = titoli;
    if (showAnnoProduzione) filtro_update.annoproduzione = parseInt(annoProduzione);
    if (showDal && dal !== '') filtro_update.inizio = dal;
    if (showAl && al !== '') filtro_update.fine = al;
    if (showTipologiaFilm && tipologiaFilm !== '') filtro_update.tipologiafilm = tipologiaFilm;
    if (showPrimaProgrammazione && primaProgrammazioneDal !== '' && primaProgrammazioneAl !== '') {
      filtro_update.primaprogrammazionedal = primaProgrammazioneDal;
      filtro_update.primaprogrammazioneal = primaProgrammazioneAl;
    }
    if (showDistributori && distributori !== '') filtro_update.distributori = distributori;
    if (showDistributoriContabili && distributoriContabili !== '')
      filtro_update.distributoricontabili = distributoriContabili;
    if (showRegioni && regioni) filtro_update.regioni = regioni;
    if (showNazioniProduzione && nazioniProduzione !== '')
      filtro_update.nazioniproduzione = nazioniProduzione;
    if (showCircuiti && circuiti !== '') filtro_update.circuiti = circuiti;
    if (showNumeroSale) {
      filtro_update.numerosale = parseInt(numeroSale);
      filtro_update.filtronumerosale = filtroNumeroSale;
    }
    if (showAgenzie && agenzie !== '') filtro_update.agenzie = agenzie;

    setFiltro(filtro_update);
  }, [
    showTitoli,
    titoli,
    showAnnoProduzione,
    annoProduzione,
    showDal,
    dal,
    showAl,
    al,
    showTipologiaFilm,
    tipologiaFilm,
    showPrimaProgrammazione,
    primaProgrammazioneDal,
    primaProgrammazioneAl,
    showDistributori,
    distributori,
    showDistributoriContabili,
    distributoriContabili,
    showRegioni,
    regioni,
    showNazioniProduzione,
    nazioniProduzione,
    showCircuiti,
    circuiti,
    showNumeroSale,
    numeroSale,
    filtroNumeroSale,
    showAgenzie,
    agenzie
  ]);

  useEffect(() => {
    if (!data || data === undefined) {
      return;
    }

    if (formato === 'csv') {
      var a = document.createElement('a');
      a.href = URL.createObjectURL(
        new Blob([data], {
          type: 'text/csv;encoding:utf-8'
        })
      );
      a.setAttribute('download', 'analisi.csv');
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      setMessaggio("I risultati sono stati esportati nell'allegato");
      return;
    }

    if (data && (!data.data || data.data.length < 2)) {
      setMessaggio('La ricerca non ha prodotto risultati');
      return;
    }

    setDati(data.data);
  }, [data, formato]);

  return (
    <div style={{ marginBottom: '8rem' }}>
      <div className="container my-5">
        <div className="row mb-5">
          <div className="col pannello p-2 p-lg-5" style={{ maxWidth: '1100px', margin: '0 auto' }}>
            <HeaderTitoli />
            <h2 className="text-center mb-5">Analisi Pivot</h2>

            <table className="mx-auto mb-5" style={{ fontSize: '1.1rem', lineHeight: '1.2' }}>
              <tbody>
                <tr>
                  <td className="py-1" style={{ width: '6rem' }}>
                    <input
                      id="dal"
                      className="form-check-input me-2"
                      type="checkbox"
                      checked={showDal}
                      onChange={(e) => setShowDal(e.target.checked)}
                      style={{ position: 'relative', top: '-1px' }}
                    />
                    <label
                      className="form-check-label me-2"
                      htmlFor="dal"
                      style={{ fontSize: '1rem' }}>
                      Dal
                    </label>
                  </td>
                  <td className="py-1">
                    <input
                      type="date"
                      value={dal}
                      disabled={!showDal}
                      onChange={(e) => assegnaDal(e.target.value)}
                      onBlur={sanitizeDal}
                      className="form-control w-100"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="py-1">
                    <input
                      id="al"
                      className="form-check-input me-2"
                      type="checkbox"
                      checked={showAl}
                      onChange={(e) => setShowAl(e.target.checked)}
                      style={{ position: 'relative', top: '-1px' }}
                    />
                    <label
                      className="form-check-label me-2"
                      htmlFor="al"
                      style={{ fontSize: '1rem' }}>
                      Al
                    </label>
                  </td>
                  <td className="py-1" style={{ width: '10rem' }}>
                    <input
                      type="date"
                      value={al}
                      disabled={!showAl}
                      onChange={(e) => assegnaAl(e.target.value)}
                      onBlur={sanitizeAl}
                      className="form-control w-100"
                    />
                  </td>
                </tr>
              </tbody>
            </table>

            <p className="text-center mb-3" style={{ fontSize: '1.4rem' }}>
              Filtri
            </p>
            <table className="w-100" style={{ fontSize: '1.1rem', lineHeight: '1.2' }}>
              <tbody>
                <tr>
                  <td className="py-1 w-50">
                    <input
                      id="titolo"
                      className="form-check-input me-2"
                      type="checkbox"
                      checked={showTitoli}
                      onChange={(e) => {
                        setShowTitoli(e.target.checked);
                        if (!e.target.checked) {
                          setTitoli([]);
                        }
                      }}
                      style={{ position: 'relative', top: '-1px' }}
                    />
                    <label
                      className="form-check-label me-2"
                      htmlFor="titolo"
                      style={{ fontSize: '1rem' }}>
                      Titolo/i
                    </label>
                  </td>
                  <td className="py-1 w-50" style={{ width: '20rem' }}>
                    <FiltroFilm
                      onChange={setTitoli}
                      maxHeight={'239px'}
                      selectAll={false}
                      disabled={!showTitoli}
                      mode={'multiselect'}
                      titoli={titoli}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="py-1">
                    <input
                      id="annoproduzione"
                      className="form-check-input me-2"
                      type="checkbox"
                      checked={showAnnoProduzione}
                      onChange={(e) => setShowAnnoProduzione(e.target.checked)}
                      style={{ position: 'relative', top: '-1px' }}
                    />
                    <label
                      className="form-check-label me-2"
                      htmlFor="annoproduzione"
                      style={{ fontSize: '1rem' }}>
                      Anno di produzione
                    </label>
                  </td>
                  <td className="py-1" style={{ width: '20rem' }}>
                    <input
                      type="number"
                      value={annoProduzione}
                      onChange={(e) => setAnnoProduzione(e.target.value)}
                      disabled={!showAnnoProduzione}
                      className="form-control"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="py-1">
                    <input
                      id="tipologia"
                      className="form-check-input me-2"
                      type="checkbox"
                      checked={showTipologiaFilm}
                      onChange={(e) => setShowTipologiaFilm(e.target.checked)}
                      style={{ position: 'relative', top: '-1px' }}
                    />
                    <label
                      className="form-check-label me-2"
                      htmlFor="tipologia"
                      style={{ fontSize: '1rem' }}>
                      Tipologia film
                    </label>
                  </td>
                  <td className="py-1" style={{ width: '20rem' }}>
                    <select
                      value={tipologiaFilm}
                      onChange={(e) => setTipologiaFilm(e.target.value)}
                      disabled={!showTipologiaFilm}
                      className="form-select">
                      <option value="">Tutte le tipologie</option>
                      <option value="E">EVENTO</option>
                      <option value="R">RIEDIZIONE</option>
                      <option value="P">EDIZIONE SPECIALE</option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <td className="py-1">
                    <input
                      id="primaprogram"
                      className="form-check-input me-2"
                      type="checkbox"
                      checked={showPrimaProgrammazione}
                      onChange={(e) => setShowPrimaProgrammazione(e.target.checked)}
                      style={{ position: 'relative', top: '-1px' }}
                    />
                    <label
                      className="form-check-label me-2"
                      htmlFor="primaprogram"
                      style={{ fontSize: '1rem' }}>
                      Prima programmazione
                    </label>
                  </td>
                  <td
                    className="py-1 d-flex justify-content-between align-items-center w-100 gap-3"
                    style={{ width: '20rem' }}>
                    <label htmlFor="pPDal">Dal</label>
                    <input
                      type="date"
                      id="pPDal"
                      value={primaProgrammazioneDal}
                      onChange={(e) => setPrimaProgrammazioneDal(e.target.value)}
                      disabled={!showPrimaProgrammazione}
                      onBlur={sanitizePrimaProgrammazioneDal}
                      className="form-control"
                    />
                    <label htmlFor="pPAl">Al</label>
                    <input
                      type="date"
                      id="pPAl"
                      value={primaProgrammazioneAl}
                      onChange={(e) => setPrimaProgrammazioneAl(e.target.value)}
                      disabled={!showPrimaProgrammazione}
                      onBlur={sanitizePrimaProgrammazioneAl}
                      className="form-control"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="py-1">
                    <input
                      id="distributore"
                      className="form-check-input me-2"
                      type="checkbox"
                      checked={showDistributori}
                      onChange={(e) => {
                        setShowDistributori(e.target.checked);
                        if (!e.target.checked) {
                          setDistributori([]);
                        }
                      }}
                      style={{ position: 'relative', top: '-1px' }}
                    />
                    <label
                      className="form-check-label me-2"
                      htmlFor="distributore"
                      style={{ fontSize: '1rem' }}>
                      Distribuzione/i
                    </label>
                  </td>
                  <td className="py-1" style={{ width: '20rem' }}>
                    <FiltroDistributori
                      onChange={setDistributori}
                      maxHeight={'239px'}
                      selectAll={false}
                      disabled={!showDistributori}
                      mode={'multiselect'}
                      itemsSelected={distributori}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="py-1">
                    <input
                      id="distributorecontabile"
                      className="form-check-input me-2"
                      type="checkbox"
                      checked={showDistributoriContabili}
                      onChange={(e) => {
                        setShowDistributoriContabili(e.target.checked);
                        if (!e.target.checked) {
                          setDistributoriContabili([]);
                        }
                      }}
                      style={{ position: 'relative', top: '-1px' }}
                    />
                    <label
                      className="form-check-label me-2"
                      htmlFor="distributorecontabile"
                      style={{ fontSize: '1rem' }}>
                      Distribuzione/i contabile/i
                    </label>
                  </td>
                  <td className="py-1" style={{ width: '20rem' }}>
                    <FiltroDistributoriContabili
                      onChange={setDistributoriContabili}
                      maxHeight={'239px'}
                      selectAll={false}
                      disabled={!showDistributoriContabili}
                      mode={'multiselect'}
                      itemsSelected={distributoriContabili}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="py-1">
                    <input
                      id="regioni"
                      className="form-check-input me-2"
                      type="checkbox"
                      checked={showRegioni}
                      onChange={(e) => {
                        setShowRegioni(e.target.checked);
                        if (!e.target.checked) {
                          setRegioni([]);
                        }
                      }}
                      style={{ position: 'relative', top: '-1px' }}
                    />
                    <label
                      className="form-check-label me-2"
                      htmlFor="regioni"
                      style={{ fontSize: '1rem' }}>
                      Regione/i
                    </label>
                  </td>
                  <td className="py-1" style={{ width: '20rem' }}>
                    <FiltroRegioni
                      onChange={setRegioni}
                      maxHeight={'239px'}
                      selectAll={false}
                      disabled={!showRegioni}
                      mode={'multiselect'}
                      itemsSelected={regioni}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="py-1">
                    <input
                      id="nazioneproduzione"
                      className="form-check-input me-2"
                      type="checkbox"
                      checked={showNazioniProduzione}
                      onChange={(e) => {
                        setShowNazioniProduzione(e.target.checked);
                        if (!e.target.checked) {
                          setNazioniProduzione([]);
                        }
                      }}
                      style={{ position: 'relative', top: '-1px' }}
                    />
                    <label
                      className="form-check-label me-2"
                      htmlFor="nazioneproduzione"
                      style={{ fontSize: '1rem' }}>
                      Nazione/i produzione
                    </label>
                  </td>
                  <td className="py-1" style={{ width: '20rem' }}>
                    <FiltroNazioniProduzione
                      onChange={setNazioniProduzione}
                      maxHeight={'239px'}
                      selectAll={false}
                      disabled={!showNazioniProduzione}
                      mode={'multiselect'}
                      itemsSelected={nazioniProduzione}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="py-1 w-50">
                    <input
                      id="nomecinema"
                      className="form-check-input me-2"
                      type="checkbox"
                      checked={showCircuiti}
                      onChange={(e) => setShowCircuiti(e.target.checked)}
                      style={{ position: 'relative', top: '-1px' }}
                    />
                    <label
                      className="form-check-label me-2"
                      htmlFor="nomecinema"
                      style={{ fontSize: '1rem' }}>
                      Circuito/i
                    </label>
                  </td>
                  <td className="py-1 w-50" style={{ width: '20rem' }}>
                    <FiltroCircuiti
                      onChange={setCircuiti}
                      maxHeight={'239px'}
                      selectAll={false}
                      disabled={!showCircuiti}
                      mode={'multiselect'}
                      itemsSelected={circuiti}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="py-1">
                    <input
                      id="numerosale"
                      className="form-check-input me-2"
                      type="checkbox"
                      checked={showNumeroSale}
                      onChange={(e) => setShowNumeroSale(e.target.checked)}
                      style={{ position: 'relative', top: '-1px' }}
                    />
                    <label
                      className="form-check-label me-2"
                      htmlFor="numerosale"
                      style={{ fontSize: '1rem' }}>
                      Numero sale
                    </label>
                  </td>
                  <td className="py-1" style={{ width: '20rem' }}>
                    <div className="d-flex w-100">
                      <div className="me-2" style={{ width: '80%' }}>
                        <select
                          value={filtroNumeroSale}
                          onChange={(e) => setFiltroNumeroSale(e.target.value)}
                          disabled={!showNumeroSale}
                          className="form-select">
                          <option value="=">Uguale a</option>
                          <option value=">">Maggiore di</option>
                          <option value="<">Minore di</option>
                        </select>
                      </div>
                      <div>
                        <input
                          type="number"
                          min="1"
                          value={numeroSale}
                          onChange={(e) => setNumeroSale(e.target.value)}
                          disabled={!showNumeroSale}
                          className="form-control"
                        />
                      </div>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td className="py-1">
                    <input
                      id="agenzia"
                      className="form-check-input me-2"
                      type="checkbox"
                      checked={showAgenzie}
                      onChange={(e) => {
                        setShowAgenzie(e.target.checked);
                        if (!e.target.checked) {
                          setAgenzie([]);
                        }
                      }}
                      style={{ position: 'relative', top: '-1px' }}
                    />
                    <label
                      className="form-check-label me-2"
                      htmlFor="agenzia"
                      style={{ fontSize: '1rem' }}>
                      Agenzia/e
                    </label>
                  </td>
                  <td className="py-1" style={{ width: '20rem' }}>
                    <FiltroAgenzie
                      onChange={setAgenzie}
                      maxHeight={'239px'}
                      selectAll={false}
                      disabled={!showAgenzie}
                      mode={'multiselect'}
                      agenzie={agenzie}
                    />
                  </td>
                </tr>
              </tbody>
            </table>

            <div className="d-flex justify-content-end">
              <button
                disabled={loading}
                onClick={() => avviaAnalisi()}
                className="btn btn-lg btn-blue-cinetel mt-5 me-1">
                Avvia Analisi
              </button>
              {/* <EsportaDati
                url={`/incassi/searches?${refresh}`}
                method="POST"
                payload={filtro}
                className="btn btn-lg btn-blue-cinetel mt-5 me-1 ms-1"
              /> */}
              <button
                disabled={loading}
                onClick={() => avviaAnalisi('csv')}
                className="btn btn-lg btn-blue-cinetel mt-5 me-1 ms-1">
                Scarica Dati
              </button>
              {/* {dati && (
                <button
                  className="btn btn-lg btn-outline-blue-cinetel mt-5 ms-1"
                  disabled={true}
                  style={{ cursor: 'not-allowed' }}>
                  Salva Analisi
                </button>
              )} */}
            </div>
          </div>
        </div>
      </div>
      {messaggio && (
        <div className="text-center">
          <h2>{messaggio}</h2>
        </div>
      )}
      {dati && !messaggio && (
        <Fragment>
          <div style={{ overflowX: 'auto', maxWidth: '100vw' }}>
            <PivotTabella2 data={dati} />
          </div>
        </Fragment>
      )}
      {!dati && loading && <Loader messaggio="Ricerca degli incassi in corso..." />}
    </div>
  );
}
