import React, { useRef } from 'react';
import Loader from '../Loader';
import { ButtonGroup, ToggleButton } from 'react-bootstrap';
import { Bar } from 'react-chartjs-2';
import {
  colori,
  printInteroConSeparatoriMigliaia
} from '../../common/utils.js';
import Form from 'react-bootstrap/Form';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { useState } from 'react';
import TabellaConfronto from './TabellaConfronto';
import EsportaDati from '../EsportaDati';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const radios = [
  { name: 'Periodo', value: 'periodo' },
  { name: 'Agenzia', value: 'agenzia' },
  { name: 'Distribuzione', value: 'distribuzione' },
  { name: 'Circuito', value: 'circuito' }
];

const plugins = [
  {
    beforeDraw: function (chart) {
      const { ctx } = chart;
      ctx.save();
      ctx.globalCompositeOperation = 'destination-over';
      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, chart.width, chart.height);
      ctx.restore();
    }
  }
];

export default function GraficoConfronto({
  dati,
  periodi,
  tipo_periodo,
  distr_selezionati,
  agenzie_selezionate,
  circuiti_selezionati
}) {
  const [verticale, setVerticale] = useState(true);
  const [ascissa, setAscissa] = useState('periodo');
  const [stacked, setStacked] = useState(true);
  const [minimo, setMinimo] = useState(0);
  const [massimo, setMassimo] = useState(100);
  const chartRef = useRef();

  if (!dati) return <Loader messaggio="Aggiornamento del grafico in corso..." />;

  function totale(array, chiave, chiave_target) {
    return array
      .filter((x) => x[chiave] === chiave_target)
      .reduce((partialSum, a) => partialSum + a.incassi, 0);
  }

  
  const buildDataSet = (asse_x) => {
    switch (asse_x) {
      case 'periodo':
        return {
          labels: periodi.map((x) => x.nome_periodo.toUpperCase()),
          datasets: [
            {
              type: 'bar',
              label: 'Incasso',
              barPercentage: 0.9,
              categoryPercentage: 1.0,
              borderRadius: 4,
              maxBarThickness: 100,
              data: periodi.map((p) => totale(dati, 'pos_periodo', p.posizione)),
              backgroundColor: periodi.map((p, id) =>
                id > 0 ? 'rgb(0, 109, 151)' : 'rgb(255, 102, 0)'
              )
            }
          ]
        };
      case 'agenzia':
        return {
          labels: agenzie_selezionate.map((x) => x.descrizione),

          datasets: periodi.map((p, idx) => {
            return {
              type: 'bar',
              label: p.nome_periodo,
              barPercentage: 0.95,
              categoryPercentage: 0.9,
              borderRadius: 4,
              maxBarThickness: 100,
              data: agenzie_selezionate.map((a) =>
                totale(
                  dati.filter((x) => x.id_agenzia === a.valore),
                  'pos_periodo',
                  p.posizione
                )
              ),
              backgroundColor: colori[idx]
            };
          })
        };
      case 'distribuzione':
        return {
          labels: distr_selezionati.map(
            (x) => x.descrizione.substr(0, 20) + (x.descrizione.length > 20 ? '...' : '')
          ),
          datasets: periodi.map((p, idx) => {
            return {
              type: 'bar',
              label: p.nome_periodo,
              barPercentage: 0.95,
              categoryPercentage: 0.9,
              maxBarThickness: 100,
              borderRadius: 4,
              data: distr_selezionati.map((d) =>
                totale(
                  dati.filter((x) => x.id_distributore === d.valore),
                  'pos_periodo',
                  p.posizione
                )
              ),
              backgroundColor: colori[idx]
            };
          })
        };
      case 'circuito':
        return {
          labels: circuiti_selezionati.map(
            (x) => x.descrizione.substr(0, 20) + (x.descrizione.length > 20 ? '...' : '')
          ),
          datasets: periodi.map((p, idx) => {
            return {
              type: 'bar',
              label: p.nome_periodo,
              barPercentage: 0.95,
              categoryPercentage: 0.9,
              borderRadius: 4,
              maxBarThickness: 100,
              data: circuiti_selezionati.map((c) =>
                totale(
                  dati.filter((x) => x.id_circuito === c.valore),
                  'pos_periodo',
                  p.posizione
                )
              ),
              backgroundColor: colori[idx]
            };
          })
        };
      default:
        return null;
    }
  };

  const dati_grafico = buildDataSet(ascissa);
  // 

  let max_assoluto = 0;
  const size_data = dati_grafico.datasets[0].data.length;
  for (let i = 0; i < size_data; i++) {
    const val = dati_grafico.datasets
      .map((s) => s.data[i])
      .reduce((partialSum, a) => partialSum + a, 0);
    max_assoluto = Math.max(max_assoluto, val);
  }
  max_assoluto = Math.round(max_assoluto);

  let dati_grafico_mostrati = {
    labels: [...dati_grafico.labels],
    datasets: dati_grafico.datasets.map((x) => {
      return {
        ...x,
        data: [...x.data]
      };
    })
  };
  const tot_min = Math.round((minimo * max_assoluto) / 100);
  const tot_max = Math.round((massimo * max_assoluto) / 100) + 1;
  for (let i = size_data - 1; i >= 0; i--) {
    const totale = dati_grafico_mostrati.datasets
      .map((x) => x.data[i])
      .reduce((partialSum, a) => partialSum + a, 0);
    // 
    // 
    if (totale > tot_max || totale < tot_min) {
      // 
      dati_grafico_mostrati.datasets.forEach((x) => x.data.splice(i, 1));
      dati_grafico_mostrati.labels.splice(i, 1);
    }
  }

  const options = {
    responsive: true,
    aspectRatio: 1.5,
    indexAxis: verticale ? 'x' : 'y',
    interaction: {
      intersect: true,
      mode: verticale ? 'x' : 'y'
    },
    scales: {
      y: {
        stacked: stacked,
        ticks: {
          color: 'black',
          font: {
            size: 14
          }
          // callback: function (value, index, ticks) {
          //     return printInteroConSeparatoriMigliaia(value);
          // }
        }
        // min: Math.round(minimo * max_assoluto / 100),
        // max: Math.round(massimo * max_assoluto / 100)
      },
      x: {
        stacked: stacked,
        ticks: {
          color: 'black',
          font: {
            size: 14
          }
        }
      }
    },
    plugins: {
      legend: {
        position: 'top',
        display: ascissa !== 'periodo'
      },
      title: {
        display: true,
        text: 'Incassi per ' + tipo_periodo,
        color: 'black',
        padding: {
          top: 10,
          bottom: 30
        },
        font: {
          size: 28,
          weight: '500',
          family: 'Barlow'
        }
      }
    }
  };

  return (
    <div className="mb-5" style={{ maxWidth: '900px', margin: '0 auto' }}>
      <ButtonGroup className="d-block mb-5 text-center">
        {radios.map((radio, idx) => (
          <ToggleButton
            key={idx}
            id={`radio-${idx}`}
            type="radio"
            variant="outline-blue-cinetel"
            name="radio"
            value={radio.value}
            checked={ascissa === radio.value}
            onChange={(e) => setAscissa(e.currentTarget.value)}>
            {radio.name}
          </ToggleButton>
        ))}
      </ButtonGroup>

      <div className="row mt-4 mb-3">
        <div className="col-6">
          <label className="d-block mb-2">
            <input
              name="presente"
              type="checkbox"
              checked={stacked}
              onChange={() => setStacked((val) => !val)}
              className="form-check-input position-relative me-1"
              style={{ top: '-1px', fontSize: '1.2rem' }}
            />
            Impila i dati dei vari periodi
          </label>
        </div>
        <div className="col-6">
          <label className="d-block mb-2">
            <input
              name="presente"
              type="checkbox"
              checked={verticale}
              onChange={() => setVerticale((val) => !val)}
              className="form-check-input position-relative me-1"
              style={{ top: '-1px', fontSize: '1.2rem' }}
            />
            Mostra barre in verticale
          </label>
        </div>
        <div className="col-6">
          <Form.Label className="mb-1" style={{ fontWeight: minimo > 0 ? 'bold' : 'normal' }}>
            Totale minimo visualizzato:{' '}
            {printInteroConSeparatoriMigliaia((minimo * max_assoluto) / 100)} €
          </Form.Label>
          <Form.Range
            value={minimo}
            onChange={(e) => setMinimo(parseInt(e.target.value))}
            title={minimo + '%'}
          />
        </div>
        <div className="col-6">
          <Form.Label className="mb-1" style={{ fontWeight: massimo < 100 ? 'bold' : 'normal' }}>
            Totale massimo visualizzato:{' '}
            {printInteroConSeparatoriMigliaia((massimo * max_assoluto) / 100)} €
          </Form.Label>
          <Form.Range
            value={massimo}
            onChange={(e) => setMassimo(parseInt(e.target.value))}
            title={massimo + '%'}
          />
        </div>
      </div>

      <Bar ref={chartRef} data={dati_grafico_mostrati} options={options} plugins={plugins} />
      {/* <EsportaDati data={dati} nomeFile="ConfrontoPeriodi" disabled={true} /> */}

      <TabellaConfronto
        dati={dati}
        periodi={periodi}
        tipo={ascissa}
        totale_min={tot_min}
        totale_max={tot_max}
      />
    </div>
  );
}
