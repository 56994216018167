import {
  printInteroConSeparatoriMigliaia,
  printInteroConSeparatoriMigliaiaRealTime
} from '../../common/utils';

export default function TabellaRealTimeBoxOffice({dati}) {
  return (
    <table className="mx-auto shadow">
      <thead>
        <tr style={{ color: 'white', backgroundColor: 'rgb(0, 109, 151)' }}>
          <th className="px-3 py-1" style={{ width: '50rem', fontWeight: 'normal' }}>
            Titolo
          </th>
          <th className="px-3 py-1 text-end" style={{ width: '25rem', fontWeight: 'normal' }}>
            Distribuzione
          </th>
          <th className="px-3 py-1 text-end" style={{ width: '6rem', fontWeight: 'normal' }}>
            Strutture
          </th>
          <th className="px-3 py-1 text-end" style={{ width: '14rem', fontWeight: 'normal' }}>
            Incasso
          </th>
          <th className="px-3 py-1 text-end" style={{ width: '6rem', fontWeight: 'normal' }}>
            Presenze
          </th>
          <th className="px-3 py-1 text-end" style={{ width: '6rem', fontWeight: 'normal' }}>
            Media
          </th>
        </tr>
      </thead>
      <tbody>
        {dati.map((x, idx) => {
          return (
            <tr
              key={x.progress_film}
              style={{
                backgroundColor: idx % 2 === 0 ? 'white' : 'whitesmoke'
              }}>
              <td className="px-3 realtime-link"><a href={`/static/realtime/films/${x.progress_film}`}>{x.titolo}</a></td>
              <td className="px-3 text-end">{x.casa_distrib}</td>
              <td className="px-3 text-end">{x.conta_sale}</td>
              <td className="px-3 text-end">
                € {printInteroConSeparatoriMigliaiaRealTime(x.sommaincasso)}
              </td>
              <td className="px-3 text-end">
                {printInteroConSeparatoriMigliaia(x.sommapresenze)}
              </td>
              <td className="px-3 text-end">
                {printInteroConSeparatoriMigliaia(x.sommaincasso / x.conta_sale)}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
