import { printInteroConSeparatoriMigliaia } from "../../common/utils";
import { BsFillArrowUpCircleFill, BsFillArrowDownCircleFill } from 'react-icons/bs';
import { Badge } from 'react-bootstrap';

function totale(array, chiave_sommata) {
    return array.reduce((partialSum, a) => partialSum + a[chiave_sommata], 0);
}

export default function ItemDato({ tipo, distributore, ispast, periodo, periodo_before }) {
    let totali = periodo.totali;
    if (distributore)
        totali = totali.filter(x => x.progress_distrib === distributore.valore);

    const totale_now = totale(totali, tipo);
    let variazione = null;
    if (periodo_before) {
        let totali_last = periodo_before.totali;
        if(distributore)
            totali_last = totali_last.filter(x => x.progress_distrib === distributore.valore);
        const totale_last = totale(totali_last, tipo);
        if (totale_last > 0)
            variazione = Math.round( -1 * ((totale_now - totale_last) / totale_now) * 100);
    }

    return (
        <div className="col-lg text-center text-lg-end">
            {!distributore && tipo !== 'presenze' && (
                <p className='mb-0 mb-lg-3'>
                    <Badge bg='' className='bg-gradient' style={{whiteSpace: 'break-spaces', 'fontSize': '1rem', 'fontWeight': 500, 'backgroundColor': ispast? 'rgb(0, 109, 151)':'rgb(255, 102, 0)' }}>
                        {periodo.nome_periodo.replace('-', '\n')}
                    </Badge>
                </p>
            )}
            <p style={{ 'fontWeight': 'bold', 'fontSize': distributore ? '1.0rem' : '1.4rem', 'marginBottom': '-0.2rem' }}>
                {tipo === 'incassi' ? '€ ' : ''}{printInteroConSeparatoriMigliaia(totale_now)}
            </p>
            {variazione && (
                <p className='mb-2 mb-lg-0' style={{ 'color': variazione > 0 ? 'green' : 'red' }}>
                    {variazione}% {variazione > 0 ?
                        <BsFillArrowUpCircleFill style={{ 'position': 'relative', 'top': '-2px' }} /> :
                        <BsFillArrowDownCircleFill style={{ 'position': 'relative', 'top': '-2px' }} />}
                </p>
            )}
        </div>
    )
}