import React, { useState, useEffect } from 'react';
import { BsTable } from 'react-icons/bs';
import fetcher, { baseurl } from '../api/fetcher';

export default function EsportaDati({ url="", method="GET", payload={}, disabled= false, className=null }) {
  const [file, setFile] = useState(null);
  const [filename, setFilename] = useState(null);
  const [loading, setLoading] = useState(false);

  const getExport = () => {
    setLoading(true);
    fetcher({
      url: '/exportgateway/byurl',
      method: 'POST',
      data: {
        url: `${baseurl}${url}`,
        method,
        payload
      },
      headers: {
        'Content-Type': 'application/json'
      },
      responseType: 'blob'
    })
      .then(({ data, headers }) => { 
        setFile(data);
        setFilename(headers["content-disposition"].split("filename=")[1].replace(/['"]/g, ''))
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!file || !filename) {
      return;
    }

    var a = document.createElement('a');
    a.href = URL.createObjectURL(new Blob([file]), {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    });
    a.setAttribute('download', filename);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    setFile(null);
  }, [file, filename]);

  return (!className) ?
  (
    <div className="text-center mt-3">
      <button
        className="btn btn-outline-blue-cinetel mx-1"
        title="Scarica in formato excel"
        disabled={disabled || loading}
        onClick={(e) => getExport()}>
        <BsTable className="position-relative me-1" style={{ marginBottom: '3px' }} />
        {loading ? 'Attendere...' : 'Esporta in Excel'}
      </button>
    </div>
  ): (
    <button
        className={className}
        title="Scarica in formato excel"
        disabled={disabled || loading}
        onClick={(e) => getExport()}>
        <BsTable className="position-relative me-1" style={{ marginBottom: '3px' }} />
        {loading ? 'Attendere...' : 'Esporta in Excel'}
      </button>
  )
}
