import React from 'react';
import { Outlet } from 'react-router-dom';
import Footer from './Footer';
import NavbarApp from './NavbarApp';
import VersionDisclaimer from './VersionDisclaimer';

export default function Layout() {
  return (
    <div>
      <NavbarApp />
      <VersionDisclaimer />
      <div className="content">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
}
