import React from 'react';
import './index.css';
import 'react-toastify/dist/ReactToastify.min.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import ModeDisclaimer from './components/ModeDisclaimer';
import { createRoot } from 'react-dom/client';
import { ToastContainer } from 'react-toastify';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  // <React.StrictMode>
  <>
    <BrowserRouter basename="/static/">
      <App />
    </BrowserRouter>
    <ModeDisclaimer />
    <ToastContainer autoClose={3000}/>
  </>
  // </React.StrictMode>
);
