import { BsSearch } from 'react-icons/bs';
import { Link } from 'react-router-dom';

export default function ItemClassificaNew({ posizione, link = null, titolo, sottotitolo = null, valore, click = null, percentuale = 0 }) {

    return (
        <div className="item-classifica d-flex align-items-center" onClick={click ? click : null} style={{ 'fontWeight': '500', cursor: click ? 'pointer' : 'default', 'borderBottom': '1px solid gainsboro', 'overflow': 'hidden', 'marginBottom': '0px' }}>
            <div className="bg-gradient" style={{'textAlign': 'center', 'fontSize': '1.2rem', 'minWidth': '3rem', 'color': 'white', 'padding': '0.5rem 0rem' }}>
                {posizione}
            </div>
            <div className="ps-2 flex-grow-1 ellipsis" title={titolo}>
                {link && (
                    <Link to={link}>
                        <BsSearch className="me-1" title="Clicca per aprire il link" />
                    </Link>
                )}
                {titolo}
                {sottotitolo && <small className="cittaSmall">{sottotitolo}</small>}
            </div>
            <div className="px-2 text-end" style={{ minWidth: '7rem', lineHeight: '1.2' }}>
                {valore}
                <span className='d-block' style={{ fontSize: '0.8rem', fontStyle: 'italic', color: 'gray' }}>
                    {percentuale > 0 && Math.round(percentuale * 100) / 100 + '%'}
                </span>
            </div>
        </div >
    )

}