import React from 'react';
import useFetch from '../hooks/useFetch';
import { AiOutlineAlert } from 'react-icons/ai';

export default function VersionDisclaimer() {
  const {
    data: serverInfo
  } = useFetch('/info');

  if (serverInfo && process.env.REACT_APP_VERSION === serverInfo.version.build) {
    return null;
  }

  return (
    <div className="text-bg-danger p-1 mt-1 mx-5 shadow rounded d-flex flex-row text-center justify-content-between align-items-center">
      <AiOutlineAlert size="1.2rem" />
      <p className="m-0">
        Le versioni del Client e del Server non sono allineate. Questo potrebbe causare dei
        malfunzionamenti.
      </p>
      <AiOutlineAlert size="1.2rem" />
    </div>
  );
}
