import React from 'react';
import { Spinner } from 'react-bootstrap'

export default function Loader({ messaggio }) {

    return (
        <div className="text-center my-5">
            <Spinner animation="border" variant="dark" />
            <p style={{ 'fontSize': '1.6rem', 'fontStyle': 'italic' }}>
                {messaggio}
            </p>
        </div>
    )

}