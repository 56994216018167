import { isEmpty, isNumber } from 'lodash-es';
import React from 'react';
import { printData } from '../../common/utils';

export default function DatiFilm({ film }) {
  return (
    <div className="pannello p-3 sticky-top" style={{ top: '1rem' }}>
      <span style={{ fontWeight: '500', fontSize: '1rem' }}>Distribuzione</span>
      <p>{film.distributore}</p>

      <span style={{ fontWeight: '500', fontSize: '1rem' }}>Nazione</span>
      <p>{film.nazione}</p>

      <span style={{ fontWeight: '500', fontSize: '1rem' }}>Genere</span>
      <p>{film.genere}</p>

      <span style={{ fontWeight: '500', fontSize: '1rem' }}>Prima programmazione</span>
      <p>{printData(new Date(film.prima_programmazione))}</p>

      <span style={{ fontWeight: '500', fontSize: '1rem' }}>Durata</span>
      <p>{film.durata}m</p>

      <span style={{ fontWeight: '500', fontSize: '1rem' }}>Cast</span>
      <p>{film.attori.split(', ').join(', ')}</p>

      <span style={{ fontWeight: '500', fontSize: '1rem' }}>Descrizione</span>
      <p>{!isEmpty(film.descrizione) ? film.descrizione : '-'}</p>

      <span style={{ fontWeight: '500', fontSize: '1rem' }}>Numero nulla osta</span>
      <p>{isNumber(film.nulla_osta) ? film.nulla_osta : '-'}</p>

      <span style={{ fontWeight: '500', fontSize: '1rem' }}>Data nulla osta</span>
      <p>{!isEmpty(film.data_nulla_osta) ? film.data_nulla_osta : '-'}</p>
    </div>
  );
}
