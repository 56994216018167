import React from 'react';
import { useParams } from 'react-router-dom';
import {
  printInteroConSeparatoriMigliaia,
  printInteroConSeparatoriMigliaiaRealTime
} from '../../common/utils';
import useFetch from '../../hooks/useFetch';

export default function TabellaRealTimeDettaglioFilm() {
  let params = useParams();
  const { data } = useFetch(`realtime/films/${params.progress_film}`);

  if (!data) {
    return <></>;
  }

  return (
    <div className="container mt-5" style={{ marginBottom: '8rem', maxWidth: '1100px' }}>
      <div className="row my-5">
        <div className="col-12">
          <h4>
            Dati per il Film: {data.filminfo.titolo} [ {data.filminfo.casa_distrib} ]
          </h4>
        </div>
      </div>
      <div className="row">
        <table className="mx-auto shadow">
          <thead>
            <tr style={{ color: 'white', backgroundColor: 'rgb(0, 109, 151)' }}>
              <th className="px-3 py-1" style={{ width: '6rem', fontWeight: 'normal' }}>
                Agenzia
              </th>
              <th className="px-3 py-1" style={{ width: '10rem', fontWeight: 'normal' }}>
                Città
              </th>
              <th
                className="px-3 py-1 text-center"
                style={{ width: '18rem', fontWeight: 'normal' }}>
                Cinema
              </th>
              <th className="px-3 py-1 text-center" style={{ width: '6rem', fontWeight: 'normal' }}>
                Sala
              </th>
              <th className="px-3 py-1 text-center" style={{ width: '6rem', fontWeight: 'normal' }}>
                Spett.
              </th>
              <th className="px-3 py-1 text-center" style={{ width: '6rem', fontWeight: 'normal' }}>
                Data
              </th>
              <th className="px-3 py-1 text-center" style={{ width: '6rem', fontWeight: 'normal' }}>
                Incasso
              </th>
              <th className="px-3 py-1 text-center" style={{ width: '6rem', fontWeight: 'normal' }}>
                Presenze
              </th>
            </tr>
          </thead>
          <tbody>
            {data.dettaglio_film.map((x, idx) => {
              x.data = new Date(x.data);

              return (
                <tr
                  key={idx}
                  style={{
                    backgroundColor: idx % 2 === 0 ? 'white' : 'whitesmoke'
                  }}>
                  <td className="px-3 realtime-link">
                    <a href={`/static/realtime/agenzie/${x.prog_agenz}`}>{x.nome_agenz}</a>
                  </td>
                  <td className="px-3">{x.citta}</td>
                  <td className="px-3 text-center">{x.nome_cinema}</td>
                  <td className="px-3 text-center">{x.sala_id}</td>
                  <td className="px-3 text-center">{x.spettacolo}</td>
                  <td className="px-3 text-center">
                    {x.data.toLocaleDateString('it-IT', {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit'
                    })}
                  </td>
                  <td className="px-3 text-right">
                    € {printInteroConSeparatoriMigliaiaRealTime(x.incasso)}
                  </td>
                  <td className="px-3 text-right">
                    {printInteroConSeparatoriMigliaia(x.presenze)}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
