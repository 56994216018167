import useFetch from '../../hooks/useFetch';
import { printNumeroCompatto, printInteroConSeparatoriMigliaia } from '../../common/utils';
import { useEffect, useState } from 'react';

const getDescrizionePeriodo = p => {
    let start = new Date(p['iniz_per']);
    let end = new Date(p['fine_per']);
    let start_str = ("0" + start.getDate()).slice(-2) + '/' + ("0" + (start.getMonth() + 1)).slice(-2) + '/' + start.getFullYear();
    let end_str = ("0" + end.getDate()).slice(-2) + '/' + ("0" + (end.getMonth() + 1)).slice(-2) + '/' + end.getFullYear();
    if (p['iniz_per'] !== p['fine_per'])
        return 'dal ' + start_str + ' al ' + end_str;
    return 'del ' + start_str;
}

export default function TotaliPeriodo({ periodo, intervalloDate, filtro }) {
    const { data } = useFetch(periodo != null ? `totali/${periodo.id_periodo}` : (filtro?.id === 0 ? `totali/0?from=${intervalloDate[0]}&to=${intervalloDate[1]}${intervalloDate[2] ? '&primaprogram=1':''}` : null));
    const [dati, setDati] = useState(null);

    useEffect(() => {
        if(data){
            setDati(data.data)
        }
    }, [data])

    if (!dati || (!periodo && filtro?.id !== 0))
        return null;

    if (!dati[0].incassi)
        return null;
        
    return (
        <div className='py-2 text-center mb-5' style={{ 'fontSize': '1.4rem', 'fontWeight': '500', 'boxShadow': 'rgb(0 0 0 / 24%) 0px 3px 8px', 'borderRadius': '8px' }}>
            Totali {getDescrizionePeriodo(periodo ?? { iniz_per: intervalloDate[0], fine_per: intervalloDate[1] })}: incassi €{printNumeroCompatto(dati[0].incassi)} | presenze {printInteroConSeparatoriMigliaia(dati[0].presenze)}
        </div>
    )
}