import React from 'react';
import { BsFillCaretLeftFill } from 'react-icons/bs';

export default function HeaderClassificaNew({ testo, click = null }) {
    return (
        <div className="sticky-top top-0 text-center px-2 py-1 ellipsis" onClick={click} style={{ 'overflow': 'hidden', 'marginBottom': '0px', backgroundColor: 'rgba(245,245, 245, 0.9)', color: 'gray', textAlign: 'center', cursor: (click ? 'pointer' : 'default') }}>
            {
                click && <BsFillCaretLeftFill className="pb-1" style={{fontSize: '1.2rem'}} />
            }
            {testo}
        </div>
    )
}