import React from 'react';

export default function HeaderTitoli() {

    return (
        <span
            className="text-center" 
            style={{ display: 'block', fontStyle: 'italic', color: 'rgb(0, 109, 151)', fontSize: '1.6rem' }}>
            Cinetel Pro
        </span>
    )
}