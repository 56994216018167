import React, { useRef } from "react";
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    ArcElement,
    Tooltip,
    Legend,
} from 'chart.js';
import { colori_blu } from "../../common/utils";
import EsportaDati from "../EsportaDati";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    ArcElement,
    Tooltip,
    Legend
);

const plugins = [{
    beforeDraw: function (chart) {
        const { ctx } = chart;
        ctx.save();
        ctx.globalCompositeOperation = 'destination-over';
        ctx.fillStyle = 'white';
        ctx.fillRect(0, 0, chart.width, chart.height);
        ctx.restore();
    }
}]

export default function GraficoRegioni({ dati, versioni }) {
    const chartRef = useRef();

    if (dati.length === 0) {
        return (
            <div className="mx-auto text-center" style={{ 'maxWidth': '800px' }}>
                <h2 className="mb-4">Dettaglio agenzie (€)</h2>
                <p>
                    Non ci sono dati da visualizzare
                </p>
            </div>
        )
    }

    const agenzie = Array.from(new Set(dati.map(d => d.agenzia)));

    const calcola_totale_agenzie = versione_corrente => {
        
        return agenzie.map(r => {
            let somma = dati.filter(d => d.agenzia === r && d.versione === versione_corrente)
                .reduce((partialSum, a) => partialSum + a.incassi, 0);
            return somma;
        });
    }

    const options = {
        plugins: {
            legend: {
                display: versioni.length > 1 ? 'top' : false
            },
            title: {
                display: true,
                text: 'Dettaglio Agenzie (€)',
                color: 'black',
                padding: {
                    top: 10,
                    bottom: 30
                },
                font: {
                    size: 28,
                    weight: '500',
                    family: 'Barlow'
                }
            }
        },
        responsive: true,
        interaction: {
            intersect: true,
            mode: 'x',
        },
        scales: {
            y: {
                stacked: true,
                ticks: {
                    color: 'black', font: {
                        size: 16,
                    }
                }
            },
            x: {
                stacked: true,
                ticks: {
                    color: 'black', font: {
                        size: 16,
                    }
                }
            }
        },
    };

    const data_grafico = {
        labels: agenzie,
        datasets: versioni.map(v => {
            return {
                label: 'Incassi' + (versioni.length > 1 ? ' ' + v : ''),
                data: calcola_totale_agenzie(v),
                barPercentage: 0.9,
                categoryPercentage: 1.0,
                borderRadius: 4,
                backgroundColor: colori_blu[v],
                maxBarThickness: 100
            }
        })
    }

    
    

    return (
        <div className="mx-auto" style={{ 'maxWidth': '800px' }}>
            {/* <h2 className="text-center mb-4">Dettaglio agenzie (€)</h2> */}
            <Bar
                ref={chartRef}
                data={data_grafico}
                options={options}
                plugins={plugins}
            />
            {/* <EsportaDati
                data={dati}
                nomeFile="IncassiAgenzie"
                disabled={true}
            /> */}
        </div>
    )

}